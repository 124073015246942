{
    "ar": {
        "False": "ـ*Fـ*aـ*lـ*sـ*eـ*|False",
        "True": "ـ*Tـ*rـ*uـ*eـ*|True",
        "add": "ـ*اـ*ضـ*فـ*|add",
        "and": "ـ*وـ*|and",
        "ask": "ـ*اـ*سـ*أـ*لـ*|ask",
        "at": "ـ*بـ*شـ*كـ*لـ*|at",
        "black": "ـ*اـ*سـ*وـ*دـ*|black",
        "blue": "ـ*اـ*زـ*رـ*قـ*|blue",
        "brown": "ـ*بـ*نـ*يـ*|brown",
        "call": "ـ*cـ*aـ*lـ*lـ*|call",
        "clear": "ـ*مـ*سـ*حـ*|clear",
        "color": "ـ*لـ*وـ*نـ*|color",
        "comma": "ـ*،ـ*|,",
        "def": "ـ*dـ*eـ*fـ*|def",
        "define": "ـ*dـ*eـ*fـ*iـ*nـ*eـ*|define",
        "echo": "ـ*رـ*دـ*دـ*|echo",
        "elif": "ـ*وـ*إـ*لـ*اـ* ـ*اـ*ذـ*اـ*|elif",
        "else": "ـ*وـ*إـ*لـ*اـ*|else",
        "false": "ـ*fـ*aـ*lـ*sـ*eـ*|false",
        "for": "ـ*لـ*كـ*لـ*|for",
        "forward": "ـ*تـ*قـ*دـ*مـ*|forward",
        "from": "ـ*مـ*نـ*|from",
        "gray": "ـ*رـ*مـ*اـ*دـ*يـ*|gray",
        "green": "ـ*اـ*خـ*ضـ*رـ*|green",
        "if": "ـ*اـ*ذـ*اـ*|if",
        "in": "ـ*فـ*يـ*|in",
        "input": "ـ*اـ*دـ*خـ*لـ*|input",
        "is": "ـ*هـ*وـ*|ـ*هـ*يـ*|is",
        "left": "ـ*يـ*سـ*اـ*رـ*|left",
        "length": "ـ*طـ*وـ*لـ*|length",
        "not_in": "ـ*nـ*oـ*tـ* ـ*iـ*nـ*|not in",
        "or": "ـ*أـ*وـ*|or",
        "orange": "ـ*بـ*رـ*تـ*قـ*اـ*لـ*يـ*|orange",
        "pink": "ـ*زـ*هـ*رـ*يـ*|pink",
        "play": "ـ*pـ*lـ*aـ*yـ*|play",
        "pressed": "ـ*مـ*ضـ*غـ*وـ*طـ*|pressed",
        "print": "ـ*قـ*وـ*لـ*|print",
        "purple": "ـ*بـ*نـ*فـ*سـ*جـ*يـ*|purple",
        "random": "ـ*عـ*شـ*وـ*اـ*ئـ*يـ*|random",
        "range": "ـ*نـ*طـ*اـ*قـ*|range",
        "red": "ـ*اـ*حـ*مـ*رـ*|red",
        "remove": "ـ*اـ*زـ*لـ*|remove",
        "repeat": "ـ*كـ*رـ*رـ*|repeat",
        "return": "ـ*rـ*eـ*tـ*uـ*rـ*nـ*|return",
        "right": "ـ*يـ*مـ*يـ*نـ*|right",
        "sleep": "ـ*اـ*نـ*تـ*ظـ*رـ*|sleep",
        "step": "ـ*خـ*طـ*وـ*ةـ*|step",
        "times": "ـ*مـ*رـ*ةـ*|times",
        "to": "ـ*اـ*لـ*ىـ*|to",
        "to_list": "ـ*اـ*لـ*ىـ*|to",
        "true": "ـ*tـ*rـ*uـ*eـ*|true",
        "turn": "ـ*اـ*سـ*تـ*دـ*رـ*|turn",
        "while": "ـ*بـ*يـ*نـ*مـ*اـ*|while",
        "white": "ـ*اـ*بـ*يـ*ضـ*|white",
        "with": "ـ*wـ*iـ*tـ*hـ*|with",
        "yellow": "ـ*اـ*صـ*فـ*رـ*|yellow",
        "DIGIT": "0٠1١2٢3٣4٤5٥6٦7٧8٨9٩"
    },
    "be": {
        "False": "False",
        "True": "True",
        "add": "add",
        "and": "and",
        "ask": "ask",
        "at": "at",
        "black": "black",
        "blue": "blue",
        "brown": "brown",
        "call": "call",
        "clear": "clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "elif",
        "else": "else",
        "false": "false",
        "for": "for",
        "forward": "forward",
        "from": "from",
        "gray": "gray",
        "green": "green",
        "if": "if",
        "in": "in",
        "input": "input",
        "is": "is",
        "left": "left",
        "length": "length",
        "not_in": "not in",
        "or": "or",
        "orange": "orange",
        "pink": "pink",
        "play": "play",
        "pressed": "pressed",
        "print": "print",
        "purple": "purple",
        "random": "random",
        "range": "range",
        "red": "red",
        "remove": "remove",
        "repeat": "repeat",
        "return": "return",
        "right": "right",
        "sleep": "sleep",
        "step": "step",
        "times": "times",
        "to": "to",
        "to_list": "to",
        "true": "true",
        "turn": "turn",
        "while": "while",
        "white": "white",
        "with": "with",
        "yellow": "yellow",
        "DIGIT": "0123456789"
    },
    "bg": {
        "False": "Невярно|False",
        "True": "Вярно|True",
        "add": "добави|add",
        "and": "и|and",
        "ask": "попитай|ask",
        "at": "в|at",
        "black": "черно|black",
        "blue": "синьо|blue",
        "brown": "кафяво|brown",
        "call": "извикай|call",
        "clear": "изчисти|clear",
        "color": "цвят|color",
        "comma": ",",
        "def": "деф|def",
        "define": "дефинирай|define",
        "echo": "покажи|echo",
        "elif": "иначе ако|elif",
        "else": "иначе|else",
        "false": "невярно|false",
        "for": "за|for",
        "forward": "напред|forward",
        "from": "от|from",
        "gray": "сиво|gray",
        "green": "зелено|green",
        "if": "ако|if",
        "in": "в|in",
        "input": "въвеждане|input",
        "is": "е|is",
        "left": "ляво|left",
        "length": "дължина|length",
        "not_in": "не в/във|not in",
        "or": "или|or",
        "orange": "оранжево|orange",
        "pink": "розово|pink",
        "play": "play",
        "pressed": "натиснат|pressed",
        "print": "принтирай|print",
        "purple": "лилаво|purple",
        "random": "произволно|random",
        "range": "обхват|range",
        "red": "червено|red",
        "remove": "премахни|remove",
        "repeat": "повтори|repeat",
        "return": "върни|return",
        "right": "дясно|right",
        "sleep": "спи|sleep",
        "step": "стъпка|step",
        "times": "пъти|times",
        "to": "до|to",
        "to_list": "до|to",
        "true": "вярно|true",
        "turn": "завий|turn",
        "while": "докато|while",
        "white": "бяло|white",
        "with": "с/със|with",
        "yellow": "жълто|yellow",
        "DIGIT": "0123456789"
    },
    "bn": {
        "False": "False",
        "True": "True",
        "add": "add",
        "and": "and",
        "ask": "ask",
        "at": "at",
        "black": "black",
        "blue": "blue",
        "brown": "brown",
        "call": "call",
        "clear": "clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "elif",
        "else": "else",
        "false": "false",
        "for": "for",
        "forward": "forward",
        "from": "from",
        "gray": "gray",
        "green": "green",
        "if": "if",
        "in": "in",
        "input": "input",
        "is": "is",
        "left": "left",
        "length": "length",
        "not_in": "not in",
        "or": "or",
        "orange": "orange",
        "pink": "pink",
        "play": "play",
        "pressed": "pressed",
        "print": "print",
        "purple": "purple",
        "random": "random",
        "range": "range",
        "red": "red",
        "remove": "remove",
        "repeat": "repeat",
        "return": "return",
        "right": "right",
        "sleep": "sleep",
        "step": "step",
        "times": "times",
        "to": "to",
        "to_list": "to",
        "true": "true",
        "turn": "turn",
        "while": "while",
        "white": "white",
        "with": "with",
        "yellow": "yellow",
        "DIGIT": "0123456789"
    },
    "brx": {
        "False": "False",
        "True": "True",
        "add": "add",
        "and": "and",
        "ask": "ask",
        "at": "at",
        "black": "black",
        "blue": "blue",
        "brown": "brown",
        "call": "call",
        "clear": "clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "elif",
        "else": "else",
        "false": "false",
        "for": "for",
        "forward": "forward",
        "from": "from",
        "gray": "gray",
        "green": "green",
        "if": "if",
        "in": "in",
        "input": "input",
        "is": "is",
        "left": "left",
        "length": "length",
        "not_in": "not in",
        "or": "or",
        "orange": "orange",
        "pink": "pink",
        "play": "play",
        "pressed": "pressed",
        "print": "print",
        "purple": "purple",
        "random": "random",
        "range": "range",
        "red": "red",
        "remove": "remove",
        "repeat": "repeat",
        "return": "return",
        "right": "right",
        "sleep": "sleep",
        "step": "step",
        "times": "times",
        "to": "to",
        "to_list": "to",
        "true": "true",
        "turn": "turn",
        "while": "while",
        "white": "white",
        "with": "with",
        "yellow": "yellow",
        "DIGIT": "0123456789"
    },
    "ca": {
        "False": "Fals|False",
        "True": "Cert|True",
        "add": "afegeix|add",
        "and": "i|and",
        "ask": "pregunta|ask",
        "at": "a|at",
        "black": "negre|black",
        "blue": "blau|blue",
        "brown": "marró|brown",
        "call": "crida|call",
        "clear": "neteja|clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "defineix|define",
        "echo": "eco|echo",
        "elif": "sinosi|elif",
        "else": "sino|else",
        "false": "fals|false",
        "for": "per|for",
        "forward": "avança|forward",
        "from": "de|from",
        "gray": "gris|gray",
        "green": "verd|green",
        "if": "si|if",
        "in": "dins|in",
        "input": "entra|input",
        "is": "és|is",
        "left": "esquerra|left",
        "length": "mida|length",
        "not_in": "no dins|not in",
        "or": "o|or",
        "orange": "taronja|orange",
        "pink": "rosa|pink",
        "play": "reprodueix|play",
        "pressed": "pressionat|pressed",
        "print": "imprimeix|print",
        "purple": "lila|purple",
        "random": "aleatori|random",
        "range": "rang|range",
        "red": "vermell|red",
        "remove": "esborra|remove",
        "repeat": "repeteix|repeat",
        "return": "retorna|return",
        "right": "dreta|right",
        "sleep": "dorm|sleep",
        "step": "pas|step",
        "times": "vegades|times",
        "to": "fins|to",
        "to_list": "a|to",
        "true": "cert|true",
        "turn": "gira|turn",
        "while": "mentre|while",
        "white": "blanc|white",
        "with": "amb|with",
        "yellow": "groc|yellow",
        "DIGIT": "0123456789"
    },
    "cs": {
        "False": "False",
        "True": "True",
        "add": "add",
        "and": "and",
        "ask": "ask",
        "at": "at",
        "black": "black",
        "blue": "blue",
        "brown": "brown",
        "call": "call",
        "clear": "clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "elif",
        "else": "else",
        "false": "false",
        "for": "for",
        "forward": "forward",
        "from": "from",
        "gray": "gray",
        "green": "green",
        "if": "if",
        "in": "in",
        "input": "input",
        "is": "is",
        "left": "left",
        "length": "length",
        "not_in": "not in",
        "or": "or",
        "orange": "orange",
        "pink": "pink",
        "play": "play",
        "pressed": "pressed",
        "print": "print",
        "purple": "purple",
        "random": "random",
        "range": "range",
        "red": "red",
        "remove": "remove",
        "repeat": "repeat",
        "return": "return",
        "right": "right",
        "sleep": "sleep",
        "step": "step",
        "times": "times",
        "to": "to",
        "to_list": "to",
        "true": "true",
        "turn": "turn",
        "while": "while",
        "white": "white",
        "with": "with",
        "yellow": "yellow",
        "DIGIT": "0123456789"
    },
    "cy": {
        "False": "False",
        "True": "True",
        "add": "adio|add",
        "and": "a|and",
        "ask": "gofyn|ask",
        "at": "ar|at",
        "black": "du|black",
        "blue": "glas|blue",
        "brown": "brown",
        "call": "call",
        "clear": "clear",
        "color": "lliw|color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "adleisio|echo",
        "elif": "elif",
        "else": "arall|else",
        "false": "false",
        "for": "ar gyfer|for",
        "forward": "ymlaen|forward",
        "from": "o|from",
        "gray": "llwyd|gray",
        "green": "gwyrdd|green",
        "if": "os|if",
        "in": "mewn|in",
        "input": "mewnbwn|input",
        "is": "yw|is",
        "left": "chwith|left",
        "length": "hyd|length",
        "not_in": "dim mewn|not in",
        "or": "neu|or",
        "orange": "oren|orange",
        "pink": "pinc|pink",
        "play": "play",
        "pressed": "gwasgu|pressed",
        "print": "argraffu|print",
        "purple": "porffor|purple",
        "random": "hap|random",
        "range": "ystod|range",
        "red": "coch|red",
        "remove": "dileu|remove",
        "repeat": "ailadrodd|repeat",
        "return": "return",
        "right": "dde|right",
        "sleep": "cysgu|sleep",
        "step": "cam|step",
        "times": "gwaith|times",
        "to": "i|to",
        "to_list": "i|to",
        "true": "true",
        "turn": "troi|turn",
        "while": "tra|while",
        "white": "gwyn|white",
        "with": "with",
        "yellow": "melyn|yellow",
        "DIGIT": "0123456789"
    },
    "da": {
        "False": "Falsk|False",
        "True": "Sandt|True",
        "add": "tilføj|add",
        "and": "og|and",
        "ask": "spørg|ask",
        "at": "ved|at",
        "black": "sort|black",
        "blue": "blå|blue",
        "brown": "brun|brown",
        "call": "kald|call",
        "clear": "rens|clear",
        "color": "farve|color",
        "comma": "komma|,",
        "def": "def",
        "define": "definer|define",
        "echo": "ekko|echo",
        "elif": "ellers hvis|elif",
        "else": "ellers|else",
        "false": "falsk|false",
        "for": "for",
        "forward": "fremad|forward",
        "from": "fra|from",
        "gray": "grå|gray",
        "green": "grøn|green",
        "if": "hvis|if",
        "in": "i|in",
        "input": "input",
        "is": "er|is",
        "left": "venstre|left",
        "length": "længde|length",
        "not_in": "ikke i|not in",
        "or": "eller|or",
        "orange": "orange",
        "pink": "lyserød|pink",
        "play": "spil|play",
        "pressed": "trykket ned|pressed",
        "print": "skriv|print",
        "purple": "lilla|purple",
        "random": "tilfældighed|random",
        "range": "interval|range",
        "red": "rød|red",
        "remove": "fjern|remove",
        "repeat": "gentag|repeat",
        "return": "returner|return",
        "right": "højre|right",
        "sleep": "sov|sleep",
        "step": "skridt|step",
        "times": "gange|times",
        "to": "til|to",
        "to_list": "til|to",
        "true": "sandt|true",
        "turn": "drej|turn",
        "while": "mens|while",
        "white": "hvid|white",
        "with": "med|with",
        "yellow": "gul|yellow",
        "DIGIT": "0123456789"
    },
    "de": {
        "False": "Falsch|False",
        "True": "Wahr|True",
        "add": "hinzufügen|add",
        "and": "und|and",
        "ask": "frage|ask",
        "at": "an|at",
        "black": "Schwarz|black",
        "blue": "Blau|blue",
        "brown": "Braun|brown",
        "call": "aufruf|call",
        "clear": "abwischen|clear",
        "color": "farbe|color",
        "comma": ",",
        "def": "def",
        "define": "definiere|define",
        "echo": "echo",
        "elif": "sofalls|elif",
        "else": "sonst|else",
        "false": "falsch|false",
        "for": "für|for",
        "forward": "vorwärts|forward",
        "from": "aus|from",
        "gray": "Grau|gray",
        "green": "Grün|green",
        "if": "falls|if",
        "in": "in",
        "input": "eingabe|input",
        "is": "ist|is",
        "left": "links|left",
        "length": "länge|length",
        "not_in": "nicht in|not in",
        "or": "oder|or",
        "orange": "Orange|orange",
        "pink": "Pink|pink",
        "play": "spiele|play",
        "pressed": "gedrückt|pressed",
        "print": "drucke|print",
        "purple": "Lila|purple",
        "random": "zufällig|random",
        "range": "bereich|range",
        "red": "Rot|red",
        "remove": "entferne|remove",
        "repeat": "wiederhole|repeat",
        "return": "zurück|return",
        "right": "rechts|right",
        "sleep": "schlafe|sleep",
        "step": "schritt|step",
        "times": "mal|times",
        "to": "bis|to",
        "to_list": "zu|to",
        "true": "wahr|true",
        "turn": "drehe|turn",
        "while": "solange|while",
        "white": "Weiß|white",
        "with": "mit|with",
        "yellow": "Gelb|yellow",
        "DIGIT": "0123456789"
    },
    "el": {
        "False": "False",
        "True": "True",
        "add": "add",
        "and": "και|and",
        "ask": "ask",
        "at": "at",
        "black": "black",
        "blue": "blue",
        "brown": "brown",
        "call": "call",
        "clear": "clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "elif",
        "else": "else",
        "false": "false",
        "for": "for",
        "forward": "forward",
        "from": "from",
        "gray": "gray",
        "green": "green",
        "if": "if",
        "in": "in",
        "input": "input",
        "is": "is",
        "left": "left",
        "length": "length",
        "not_in": "not in",
        "or": "or",
        "orange": "orange",
        "pink": "pink",
        "play": "play",
        "pressed": "pressed",
        "print": "print",
        "purple": "purple",
        "random": "random",
        "range": "range",
        "red": "red",
        "remove": "remove",
        "repeat": "repeat",
        "return": "return",
        "right": "right",
        "sleep": "sleep",
        "step": "step",
        "times": "times",
        "to": "to",
        "to_list": "to",
        "true": "true",
        "turn": "turn",
        "while": "while",
        "white": "white",
        "with": "with",
        "yellow": "yellow",
        "DIGIT": "0123456789"
    },
    "en": {
        "False": "False",
        "True": "True",
        "add": "add",
        "and": "and",
        "ask": "ask",
        "at": "at",
        "black": "black",
        "blue": "blue",
        "brown": "brown",
        "call": "call",
        "clear": "clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "elif",
        "else": "else",
        "false": "false",
        "for": "for",
        "forward": "forward",
        "from": "from",
        "gray": "gray",
        "green": "green",
        "if": "if",
        "in": "in",
        "input": "input",
        "is": "is",
        "left": "left",
        "length": "length",
        "not_in": "not in",
        "or": "or",
        "orange": "orange",
        "pink": "pink",
        "play": "play",
        "pressed": "pressed",
        "print": "print",
        "purple": "purple",
        "random": "random",
        "range": "range",
        "red": "red",
        "remove": "remove",
        "repeat": "repeat",
        "return": "return",
        "right": "right",
        "sleep": "sleep",
        "step": "step",
        "times": "times",
        "to": "to",
        "to_list": "to",
        "true": "true",
        "turn": "turn",
        "while": "while",
        "white": "white",
        "with": "with",
        "yellow": "yellow",
        "DIGIT": "0123456789"
    },
    "eo": {
        "False": "False",
        "True": "True",
        "add": "aldonu|add",
        "and": "kaj|and",
        "ask": "demandu|ask",
        "at": "laŭ|lau|laux|at",
        "black": "nigra|black",
        "blue": "blua|blue",
        "brown": "bruna|brown",
        "call": "call",
        "clear": "clear",
        "color": "koloro|color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "eĥu|ehhu|ehxu|e^hu|eh^u|echo",
        "elif": "alie se|elif",
        "else": "alie|else",
        "false": "false",
        "for": "por|for",
        "forward": "antaŭen|antauen|antauxen|forward",
        "from": "el|from",
        "gray": "griza|gray",
        "green": "verda|green",
        "if": "se|if",
        "in": "en|in",
        "input": "enigu|input",
        "is": "estas|is",
        "left": "maldekstren|left",
        "length": "longo|length",
        "not_in": "not in",
        "or": "aŭ|au|aux|or",
        "orange": "oranĝa|orangha|orangxa|oran^ga|orang^a|orange",
        "pink": "rozkolora|pink",
        "play": "play",
        "pressed": "pressed",
        "print": "presu|print",
        "purple": "purpura|purple",
        "random": "hazardo|random",
        "range": "intervalo|range",
        "red": "ruĝa|rugha|rugxa|ru^ga|rug^a|red",
        "remove": "forigu|remove",
        "repeat": "ripetu|repeat",
        "return": "return",
        "right": "dekstren|right",
        "sleep": "dormu|sleep",
        "step": "paŝo|pasho|pasxo|pa^so|pas^o|step",
        "times": "fojojn|times",
        "to": "ĝis|ghis|gxis|^gis|g^is|to",
        "to_list": "al|to",
        "true": "true",
        "turn": "turnu|turn",
        "while": "dum|while",
        "white": "blanka|white",
        "with": "with",
        "yellow": "flava|yellow",
        "DIGIT": "0123456789"
    },
    "es": {
        "False": "Falso|False",
        "True": "Verdadero|True",
        "add": "añadir|add",
        "and": "y|and",
        "ask": "preguntar|ask",
        "at": "en|at",
        "black": "negro|black",
        "blue": "azul|blue",
        "brown": "marrón|brown",
        "call": "llamar|call",
        "clear": "limpiar|clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "definir|define",
        "echo": "eco|echo",
        "elif": "sinosi|elif",
        "else": "sino|else",
        "false": "falso|false",
        "for": "para|for",
        "forward": "adelante|forward",
        "from": "de|from",
        "gray": "gris|gray",
        "green": "verde|green",
        "if": "si|if",
        "in": "en|in",
        "input": "entrada|input",
        "is": "es|is",
        "left": "izquierda|left",
        "length": "longitud|length",
        "not_in": "no en|not in",
        "or": "o|or",
        "orange": "naranja|orange",
        "pink": "rosa|pink",
        "play": "reproducir|play",
        "pressed": "presionada|pressed",
        "print": "imprimir|print",
        "purple": "púrpura|purple",
        "random": "aleatorio|random",
        "range": "rango|range",
        "red": "rojo|red",
        "remove": "borrar|remove",
        "repeat": "repetir|repeat",
        "return": "retornar|return",
        "right": "derecha|right",
        "sleep": "dormir|sleep",
        "step": "paso|step",
        "times": "veces|times",
        "to": "a|to",
        "to_list": "a|to",
        "true": "verdadero|true",
        "turn": "girar|turn",
        "while": "mientras|while",
        "white": "blanco|white",
        "with": "con|with",
        "yellow": "amarillo|yellow",
        "DIGIT": "0123456789"
    },
    "et": {
        "False": "False",
        "True": "True",
        "add": "lisa|add",
        "and": "ja|and",
        "ask": "küsi|ask",
        "at": "täitsa|at",
        "black": "must|black",
        "blue": "sinine|blue",
        "brown": "pruun|brown",
        "call": "call",
        "clear": "clear",
        "color": "värv|color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "peegelda|echo",
        "elif": "muidukui|elif",
        "else": "muidu|else",
        "false": "false",
        "for": "jaoks|for",
        "forward": "edasi|forward",
        "from": "nimistust|from",
        "gray": "hall|gray",
        "green": "roheline|green",
        "if": "kui|if",
        "in": "nimistus|in",
        "input": "sisesta|input",
        "is": "on|is",
        "left": "vasakule|left",
        "length": "pikkus|length",
        "not_in": "not in",
        "or": "või|or",
        "orange": "oranž|orange",
        "pink": "roosa|pink",
        "play": "play",
        "pressed": "pressed",
        "print": "prindi|print",
        "purple": "lilla|purple",
        "random": "juhuslikult|random",
        "range": "vahemik|range",
        "red": "punane|red",
        "remove": "kustuta|remove",
        "repeat": "korda|repeat",
        "return": "return",
        "right": "paremale|right",
        "sleep": "oota|sleep",
        "step": "sammuga|step",
        "times": "korda|times",
        "to": "kuni|to",
        "to_list": "nimistusse|to",
        "true": "true",
        "turn": "pööra|turn",
        "while": "senikui|while",
        "white": "valge|white",
        "with": "with",
        "yellow": "kollane|yellow",
        "DIGIT": "0123456789"
    },
    "fa": {
        "False": "False",
        "True": "True",
        "add": "add",
        "and": "and",
        "ask": "بپرس|ask",
        "at": "at",
        "black": "سیاه|black",
        "blue": "آبی|blue",
        "brown": "قهوه ای|brown",
        "call": "call",
        "clear": "clear",
        "color": "رنگ|color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "elif",
        "else": "else",
        "false": "false",
        "for": "for",
        "forward": "به جلو|forward",
        "from": "from",
        "gray": "خاکستری|gray",
        "green": "سبز|green",
        "if": "if",
        "in": "in",
        "input": "input",
        "is": "is",
        "left": "چپ|left",
        "length": "length",
        "not_in": "not in",
        "or": "or",
        "orange": "نارنجی|orange",
        "pink": "صورتی|pink",
        "play": "play",
        "pressed": "pressed",
        "print": "چاپ|print",
        "purple": "بنفش|purple",
        "random": "random",
        "range": "range",
        "red": "قرمز|red",
        "remove": "remove",
        "repeat": "repeat",
        "return": "return",
        "right": "راست|right",
        "sleep": "sleep",
        "step": "step",
        "times": "times",
        "to": "to",
        "to_list": "to",
        "true": "true",
        "turn": "دور بزن|turn",
        "while": "while",
        "white": "سفید|white",
        "with": "with",
        "yellow": "زرد|yellow",
        "DIGIT": "0123456789"
    },
    "fi": {
        "False": "False",
        "True": "True",
        "add": "lisää|add",
        "and": "ja|and",
        "ask": "kysy|ask",
        "at": "ota|at",
        "black": "musta|black",
        "blue": "sininen|blue",
        "brown": "ruskea|brown",
        "call": "call",
        "clear": "nollaa|clear",
        "color": "väri|color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "kaiku|echo",
        "elif": "muutenjos|elif",
        "else": "muuten|else",
        "false": "false",
        "for": "jokaiselle|for",
        "forward": "eteenpäin|forward",
        "from": "listasta|from",
        "gray": "harmaa|gray",
        "green": "vihreä|green",
        "if": "jos|if",
        "in": "listassa|in",
        "input": "syöte|input",
        "is": "on|is",
        "left": "vasen|left",
        "length": "pituus|length",
        "not_in": "ei kohteessa|not in",
        "or": "tai|or",
        "orange": "oranssi|orange",
        "pink": "vaaleanpunainen|pink",
        "play": "play",
        "pressed": "painettu|pressed",
        "print": "tulosta|print",
        "purple": "violetti|purple",
        "random": "satunnainen|random",
        "range": "väli|range",
        "red": "punainen|red",
        "remove": "poista|remove",
        "repeat": "toista|repeat",
        "return": "return",
        "right": "oikea|right",
        "sleep": "nuku|sleep",
        "step": "askel|step",
        "times": "kertaa|times",
        "to": "asti|to",
        "to_list": "listaksi|to",
        "true": "true",
        "turn": "käänny|turn",
        "while": "kun|while",
        "white": "valkoinen|white",
        "with": "with",
        "yellow": "keltainen|yellow",
        "DIGIT": "0123456789"
    },
    "fr": {
        "False": "Faux|False",
        "True": "Vrai|True",
        "add": "ajoute|add",
        "and": "et|and",
        "ask": "demande|ask",
        "at": "au|at",
        "black": "noir|black",
        "blue": "bleu|blue",
        "brown": "marron|brown",
        "call": "appelle|call",
        "clear": "effacer|clear",
        "color": "couleur|color",
        "comma": ",",
        "def": "def",
        "define": "définis|define",
        "echo": "réponds|echo",
        "elif": "sinon si|elif",
        "else": "sinon|else",
        "false": "faux|false",
        "for": "pour|for",
        "forward": "avance|forward",
        "from": "de|from",
        "gray": "gris|gray",
        "green": "vert|green",
        "if": "si|if",
        "in": "dans|in",
        "input": "demande|input",
        "is": "est|is",
        "left": "gauche|left",
        "length": "longueur|length",
        "not_in": "pas dans|not in",
        "or": "ou|or",
        "orange": "orange",
        "pink": "rose|pink",
        "play": "joue|play",
        "pressed": "pressé|pressed",
        "print": "affiche|print",
        "purple": "violet|purple",
        "random": "hasard|random",
        "range": "intervalle|range",
        "red": "rouge|red",
        "remove": "supprime|remove",
        "repeat": "répète|repete|repeat",
        "return": "return",
        "right": "droite|right",
        "sleep": "dors|sleep",
        "step": "pas|step",
        "times": "fois|times",
        "to": "à|to",
        "to_list": "à|to",
        "true": "vrai|true",
        "turn": "tourne|turn",
        "while": "tant que|while",
        "white": "blanc|white",
        "with": "avec|with",
        "yellow": "jaune|yellow",
        "DIGIT": "0123456789"
    },
    "fr_CA": {
        "False": "False",
        "True": "True",
        "add": "ajoute|add",
        "and": "et|and",
        "ask": "demande|ask",
        "at": "au|at",
        "black": "noir|black",
        "blue": "bleu|blue",
        "brown": "marron|brown",
        "call": "appelle|call",
        "clear": "effacer|clear",
        "color": "couleur|color",
        "comma": ",",
        "def": "def",
        "define": "définis|define",
        "echo": "réponds|echo",
        "elif": "sinon si|elif",
        "else": "sinon|else",
        "false": "false",
        "for": "pour|for",
        "forward": "avance|forward",
        "from": "de|from",
        "gray": "gris|gray",
        "green": "vert|green",
        "if": "si|if",
        "in": "dans|in",
        "input": "demande|input",
        "is": "est|is",
        "left": "gauche|left",
        "length": "longueur|length",
        "not_in": "pas dans|not in",
        "or": "ou|or",
        "orange": "orange",
        "pink": "rose|pink",
        "play": "joue|play",
        "pressed": "pressé|pressed",
        "print": "affiche|print",
        "purple": "violet|purple",
        "random": "hasard|random",
        "range": "intervalle|range",
        "red": "rouge|red",
        "remove": "supprime|remove",
        "repeat": "répète|repete|repeat",
        "return": "return",
        "right": "droite|right",
        "sleep": "dors|sleep",
        "step": "pas|step",
        "times": "fois|times",
        "to": "à|to",
        "to_list": "à|to",
        "true": "true",
        "turn": "tourne|turn",
        "while": "tant que|while",
        "white": "blanc|white",
        "with": "avec|with",
        "yellow": "jaune|yellow",
        "DIGIT": "0123456789"
    },
    "fy": {
        "False": "False",
        "True": "True",
        "add": "add",
        "and": "and",
        "ask": "ask",
        "at": "at",
        "black": "black",
        "blue": "blue",
        "brown": "brown",
        "call": "call",
        "clear": "clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "elif",
        "else": "else",
        "false": "false",
        "for": "for",
        "forward": "forward",
        "from": "from",
        "gray": "gray",
        "green": "green",
        "if": "if",
        "in": "in",
        "input": "input",
        "is": "is",
        "left": "left",
        "length": "length",
        "not_in": "not in",
        "or": "or",
        "orange": "orange",
        "pink": "pink",
        "play": "play",
        "pressed": "pressed",
        "print": "print",
        "purple": "purple",
        "random": "random",
        "range": "range",
        "red": "red",
        "remove": "remove",
        "repeat": "repeat",
        "return": "return",
        "right": "right",
        "sleep": "sleep",
        "step": "step",
        "times": "times",
        "to": "to",
        "to_list": "to",
        "true": "true",
        "turn": "turn",
        "while": "while",
        "white": "white",
        "with": "with",
        "yellow": "yellow",
        "DIGIT": "0123456789"
    },
    "ga": {
        "False": "False",
        "True": "True",
        "add": "add",
        "and": "and",
        "ask": "ask",
        "at": "at",
        "black": "black",
        "blue": "blue",
        "brown": "brown",
        "call": "call",
        "clear": "clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "elif",
        "else": "else",
        "false": "false",
        "for": "for",
        "forward": "forward",
        "from": "from",
        "gray": "gray",
        "green": "green",
        "if": "if",
        "in": "in",
        "input": "input",
        "is": "is",
        "left": "left",
        "length": "length",
        "not_in": "not in",
        "or": "or",
        "orange": "orange",
        "pink": "pink",
        "play": "play",
        "pressed": "pressed",
        "print": "print",
        "purple": "purple",
        "random": "random",
        "range": "range",
        "red": "red",
        "remove": "remove",
        "repeat": "repeat",
        "return": "return",
        "right": "right",
        "sleep": "sleep",
        "step": "step",
        "times": "times",
        "to": "to",
        "to_list": "to",
        "true": "true",
        "turn": "turn",
        "while": "while",
        "white": "white",
        "with": "with",
        "yellow": "yellow",
        "DIGIT": "0123456789"
    },
    "he": {
        "False": "False",
        "True": "True",
        "add": "הוסף|add",
        "and": "וגם|and",
        "ask": "שאל|ask",
        "at": "ב|at",
        "black": "שחור|black",
        "blue": "כחול|blue",
        "brown": "חום|brown",
        "call": "call",
        "clear": "לנקות|clear",
        "color": "צבע|color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "הדהד|echo",
        "elif": "אחרתאם|elif",
        "else": "אחרת|else",
        "false": "false",
        "for": "לכל|for",
        "forward": "קדימה|forward",
        "from": "מ|from",
        "gray": "אפור|gray",
        "green": "ירוק|green",
        "if": "אם|if",
        "in": "בתוך|in",
        "input": "קלט|input",
        "is": "הוא|is",
        "left": "שמאלה|left",
        "length": "אורך|length",
        "not_in": "not in",
        "or": "או|or",
        "orange": "כתום|orange",
        "pink": "ורוד|pink",
        "play": "play",
        "pressed": "נלחץ|pressed",
        "print": "הדפס|print",
        "purple": "סגול|purple",
        "random": "אקראי|random",
        "range": "טווח|range",
        "red": "אדום|red",
        "remove": "הסר|remove",
        "repeat": "חזור|repeat",
        "return": "return",
        "right": "ימינה|right",
        "sleep": "המתן|sleep",
        "step": "צעד|step",
        "times": "פעמים|times",
        "to": "עד|to",
        "to_list": "אל|to",
        "true": "true",
        "turn": "פנה|turn",
        "while": "כלעוד|while",
        "white": "לבן|white",
        "with": "with",
        "yellow": "צהוב|yellow",
        "DIGIT": "0123456789"
    },
    "hi": {
        "False": "False",
        "True": "True",
        "add": "जोड़ना|add",
        "and": "और|and",
        "ask": "पूछें|ask",
        "at": "पर|at",
        "black": "काला|black",
        "blue": "नीला|blue",
        "brown": "भूरा|brown",
        "call": "call",
        "clear": "साफ़|clear",
        "color": "रंग|color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "गूंज|echo",
        "elif": "एलिफ|elif",
        "else": "अन्यथा|else",
        "false": "false",
        "for": "के लिये|for",
        "forward": "आगे|forward",
        "from": "से|from",
        "gray": "स्लेटी|gray",
        "green": "हरा|green",
        "if": "अगर|if",
        "in": "में|in",
        "input": "इनपुट|input",
        "is": "है|is",
        "left": "बाएं|left",
        "length": "लंबाई|length",
        "not_in": "not in",
        "or": "या|or",
        "orange": "नारंगी|orange",
        "pink": "गुलाबी|pink",
        "play": "play",
        "pressed": "दबाया गया|pressed",
        "print": "प्रिंट|print",
        "purple": "बैंगनी|purple",
        "random": "अनियमित|random",
        "range": "श्रेणी|range",
        "red": "लाल|red",
        "remove": "हटाना|remove",
        "repeat": "दोहराना|repeat",
        "return": "return",
        "right": "right",
        "sleep": "नींद|sleep",
        "step": "क़दम|step",
        "times": "बार|times",
        "to": "से|to",
        "to_list": "से|to",
        "true": "true",
        "turn": "मोड़|turn",
        "while": "व्हाइल|while",
        "white": "सफ़ेद|white",
        "with": "with",
        "yellow": "पीला|yellow",
        "DIGIT": "0123456789"
    },
    "hr": {
        "False": "False",
        "True": "True",
        "add": "add",
        "and": "and",
        "ask": "ask",
        "at": "at",
        "black": "black",
        "blue": "blue",
        "brown": "brown",
        "call": "call",
        "clear": "clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "elif",
        "else": "else",
        "false": "false",
        "for": "for",
        "forward": "forward",
        "from": "from",
        "gray": "gray",
        "green": "green",
        "if": "if",
        "in": "in",
        "input": "input",
        "is": "is",
        "left": "left",
        "length": "length",
        "not_in": "not in",
        "or": "or",
        "orange": "orange",
        "pink": "pink",
        "play": "play",
        "pressed": "pressed",
        "print": "print",
        "purple": "purple",
        "random": "random",
        "range": "range",
        "red": "red",
        "remove": "remove",
        "repeat": "repeat",
        "return": "return",
        "right": "right",
        "sleep": "sleep",
        "step": "step",
        "times": "times",
        "to": "to",
        "to_list": "to",
        "true": "true",
        "turn": "turn",
        "while": "while",
        "white": "white",
        "with": "with",
        "yellow": "yellow",
        "DIGIT": "0123456789"
    },
    "hu": {
        "False": "False",
        "True": "True",
        "add": "beszúr|add",
        "and": "és|and",
        "ask": "kérdez|ask",
        "at": "listából|at",
        "black": "fekete|black",
        "blue": "kék|blue",
        "brown": "barna|brown",
        "call": "call",
        "clear": "törlés|clear",
        "color": "szín|color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "utánoz|echo",
        "elif": "egybk-ha|elif",
        "else": "egyébként|else",
        "false": "false",
        "for": "minden|for",
        "forward": "előre|forward",
        "from": "ebből|from",
        "gray": "szürke|gray",
        "green": "zöld|green",
        "if": "ha|if",
        "in": "eleme|in",
        "input": "bekér|input",
        "is": "egyenlő|is",
        "left": "balra|left",
        "length": "hossz|length",
        "not_in": "nem eleme|not in",
        "or": "vagy|or",
        "orange": "narancs|orange",
        "pink": "pink",
        "play": "play",
        "pressed": "lenyomva|pressed",
        "print": "kiír|print",
        "purple": "lila|purple",
        "random": "random",
        "range": "szakasz|range",
        "red": "piros|red",
        "remove": "kivesz|remove",
        "repeat": "ismételd|repeat",
        "return": "return",
        "right": "jobbra|right",
        "sleep": "szundi|sleep",
        "step": "lépésenként|step",
        "times": "alkalommal|times",
        "to": "től|to",
        "to_list": "ebbe|to",
        "true": "true",
        "turn": "fordul|turn",
        "while": "amíg|while",
        "white": "fehér|white",
        "with": "with",
        "yellow": "sárga|yellow",
        "DIGIT": "0123456789"
    },
    "ia": {
        "False": "False",
        "True": "Ver|True",
        "add": "adde|add",
        "and": "e|and",
        "ask": "demanda|ask",
        "at": "a|at",
        "black": "nigre|black",
        "blue": "blau|blue",
        "brown": "brun|brown",
        "call": "appella|call",
        "clear": "clar|clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "si non si|elif",
        "else": "si non|else",
        "false": "false",
        "for": "per|for",
        "forward": "avantiare|forward",
        "from": "de|from",
        "gray": "grise|gray",
        "green": "verde|green",
        "if": "si|if",
        "in": "in",
        "input": "intrada|input",
        "is": "es|is",
        "left": "sinestre|left",
        "length": "longe|length",
        "not_in": "non in|not in",
        "or": "o|or",
        "orange": "orange",
        "pink": "rosa|pink",
        "play": "sonar|play",
        "pressed": "pressionate|pressed",
        "print": "imprimer|print",
        "purple": "purpure|purple",
        "random": "aleatori|random",
        "range": "extension|range",
        "red": "roge|red",
        "remove": "removere|remove",
        "repeat": "repetere|repeat",
        "return": "retornare|return",
        "right": "dextre|right",
        "sleep": "dormir|sleep",
        "step": "passo|step",
        "times": "vices|times",
        "to": "a|to",
        "to_list": "a|to",
        "true": "ver|true",
        "turn": "girar|turn",
        "while": "mentres|while",
        "white": "blanc|white",
        "with": "con|with",
        "yellow": "jaune|yellow",
        "DIGIT": "0123456789"
    },
    "iba": {
        "False": "False",
        "True": "True",
        "add": "add",
        "and": "and",
        "ask": "ask",
        "at": "at",
        "black": "black",
        "blue": "blue",
        "brown": "brown",
        "call": "call",
        "clear": "clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "elif",
        "else": "else",
        "false": "false",
        "for": "for",
        "forward": "forward",
        "from": "from",
        "gray": "gray",
        "green": "green",
        "if": "if",
        "in": "in",
        "input": "input",
        "is": "is",
        "left": "left",
        "length": "length",
        "not_in": "not in",
        "or": "or",
        "orange": "orange",
        "pink": "pink",
        "play": "play",
        "pressed": "pressed",
        "print": "print",
        "purple": "purple",
        "random": "random",
        "range": "range",
        "red": "red",
        "remove": "remove",
        "repeat": "repeat",
        "return": "return",
        "right": "right",
        "sleep": "sleep",
        "step": "step",
        "times": "times",
        "to": "to",
        "to_list": "to",
        "true": "true",
        "turn": "turn",
        "while": "while",
        "white": "white",
        "with": "with",
        "yellow": "yellow",
        "DIGIT": "0123456789"
    },
    "id": {
        "False": "Salah|False",
        "True": "Benar|True",
        "add": "tambah|add",
        "and": "dan|and",
        "ask": "tanya|ask",
        "at": "secara|at",
        "black": "hitam|black",
        "blue": "biru|blue",
        "brown": "cokelat|brown",
        "call": "panggil|call",
        "clear": "bersihkan|clear",
        "color": "warna|color",
        "comma": ",",
        "def": "def",
        "define": "definisikan|define",
        "echo": "gaungkan|echo",
        "elif": "lain_jika|elif",
        "else": "lainnya|else",
        "false": "salah|false",
        "for": "untuk|for",
        "forward": "maju|forward",
        "from": "dari|from",
        "gray": "abu-abu|gray",
        "green": "hijau|green",
        "if": "jika|if",
        "in": "dalam|in",
        "input": "masukan|input",
        "is": "adalah|is",
        "left": "kiri|left",
        "length": "panjang|length",
        "not_in": "tidak dalam|not in",
        "or": "atau|or",
        "orange": "oranye|orange",
        "pink": "pink",
        "play": "mulai|play",
        "pressed": "tekan|pressed",
        "print": "cetak|print",
        "purple": "ungu|purple",
        "random": "acak|random",
        "range": "batasan|range",
        "red": "merah|red",
        "remove": "hapus|remove",
        "repeat": "ulangi|repeat",
        "return": "kembali|return",
        "right": "kanan|right",
        "sleep": "tidur|sleep",
        "step": "langkah|step",
        "times": "kali|times",
        "to": "ke|to",
        "to_list": "ke|to",
        "true": "benar|true",
        "turn": "belok|turn",
        "while": "selama|while",
        "white": "putih|white",
        "with": "dengan|with",
        "yellow": "kuning|yellow",
        "DIGIT": "0123456789"
    },
    "it": {
        "False": "Falso|False",
        "True": "Vero|True",
        "add": "aggiungi|add",
        "and": "e|and",
        "ask": "chiedi|ask",
        "at": "nella posizione|at",
        "black": "nero|black",
        "blue": "blu|blue",
        "brown": "marrone|brown",
        "call": "chiama|call",
        "clear": "elimina|clear",
        "color": "colore|color",
        "comma": ",",
        "def": "def",
        "define": "definire|define",
        "echo": "eco|echo",
        "elif": "altrimenti se|elif",
        "else": "altrimenti|else",
        "false": "falso|false",
        "for": "per|for",
        "forward": "avanti|forward",
        "from": "da|from",
        "gray": "grigio|gray",
        "green": "verde|green",
        "if": "se|if",
        "in": "in",
        "input": "ingresso|input",
        "is": "è|is",
        "left": "sinistra|left",
        "length": "lunghezza|length",
        "not_in": "non in|not in",
        "or": "o|or",
        "orange": "arancione|orange",
        "pink": "rosa|pink",
        "play": "esegui|play",
        "pressed": "premuto|pressed",
        "print": "stampa|print",
        "purple": "viola|purple",
        "random": "casuale|random",
        "range": "intervallo|range",
        "red": "rosso|red",
        "remove": "rimuovi|remove",
        "repeat": "ripeti|repeat",
        "return": "restituisci|return",
        "right": "destra|right",
        "sleep": "sospendi|sleep",
        "step": "passo|step",
        "times": "volte|times",
        "to": "a|to",
        "to_list": "a|to",
        "true": "vero|true",
        "turn": "gira|turn",
        "while": "mentre|while",
        "white": "bianco|white",
        "with": "con|with",
        "yellow": "giallo|yellow",
        "DIGIT": "0123456789"
    },
    "ja": {
        "False": "ちがう|False",
        "True": "あってる|True",
        "add": "たす|add",
        "and": "それと|and",
        "ask": "きく|ask",
        "at": "at",
        "black": "くろいろ|black",
        "blue": "あおいろ|blue",
        "brown": "ちゃいろ|brown",
        "call": "よぶ|call",
        "clear": "けす|clear",
        "color": "いろ|color",
        "comma": "、|,",
        "def": "まとめ|def",
        "define": "まとめる|define",
        "echo": "まね|echo",
        "elif": "そうじゃなくてもし|elif",
        "else": "そうじゃない|else",
        "false": "ちがう|false",
        "for": "このあいだ|for",
        "forward": "まえへ|forward",
        "from": "から|from",
        "gray": "はいいろ|gray",
        "green": "みどりいろ|green",
        "if": "もし|if",
        "in": "の|in",
        "input": "いれる|input",
        "is": "は|is",
        "left": "ひだりへ|left",
        "length": "ながさ|length",
        "not_in": "not in",
        "or": "それか|or",
        "orange": "だいだいいろ|orange",
        "pink": "ももいろ|pink",
        "play": "おと|play",
        "pressed": "おした|pressed",
        "print": "みせる|print",
        "purple": "むらさきいろ|purple",
        "random": "てきとう|random",
        "range": "はんい|range",
        "red": "あかいろ|red",
        "remove": "どける|remove",
        "repeat": "くりかえす|repeat",
        "return": "もどる|return",
        "right": "みぎへ|right",
        "sleep": "やすむ|sleep",
        "step": "とばし|step",
        "times": "かい|times",
        "to": "to",
        "to_list": "to",
        "true": "あってる|true",
        "turn": "まわれ|turn",
        "while": "くりかえし|while",
        "white": "しろいろ|white",
        "with": "いっしょに|with",
        "yellow": "きいろ|yellow",
        "DIGIT": "0０1１2２3３4４5５6６7７8８9９"
    },
    "jbo": {
        "False": "False",
        "True": "True",
        "add": "add",
        "and": "and",
        "ask": "ask",
        "at": "at",
        "black": "black",
        "blue": "blue",
        "brown": "brown",
        "call": "call",
        "clear": "clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "elif",
        "else": "else",
        "false": "false",
        "for": "for",
        "forward": "forward",
        "from": "from",
        "gray": "gray",
        "green": "green",
        "if": "if",
        "in": "in",
        "input": "input",
        "is": "is",
        "left": "left",
        "length": "length",
        "not_in": "not in",
        "or": "or",
        "orange": "orange",
        "pink": "pink",
        "play": "play",
        "pressed": "pressed",
        "print": "print",
        "purple": "purple",
        "random": "random",
        "range": "range",
        "red": "red",
        "remove": "remove",
        "repeat": "repeat",
        "return": "return",
        "right": "right",
        "sleep": "sleep",
        "step": "step",
        "times": "times",
        "to": "to",
        "to_list": "to",
        "true": "true",
        "turn": "turn",
        "while": "while",
        "white": "white",
        "with": "with",
        "yellow": "yellow",
        "DIGIT": "0123456789"
    },
    "kab": {
        "False": "Yecceḍ|False",
        "True": "Swaswa|True",
        "add": "rnu|add",
        "and": "akked|and",
        "ask": "ssuter|ask",
        "at": "ar|at",
        "black": "aberkan|black",
        "blue": "amidadi|blue",
        "brown": "aqehwi|brown",
        "call": "asiwel|call",
        "clear": "sfeḍ|clear",
        "color": "ini|color",
        "comma": ",",
        "def": "def",
        "define": "sbadu|define",
        "echo": "yerra-d|echo",
        "elif": "neɣ ma yella|elif",
        "else": "neɣ|else",
        "false": "yecceḍ|false",
        "for": "i|for",
        "forward": "welleh|forward",
        "from": "seg|from",
        "gray": "azunɣid|gray",
        "green": "azegzaw|green",
        "if": "ma yella|if",
        "in": "deg|in",
        "input": "sekcem|input",
        "is": "d|is",
        "left": "ayeffus|left",
        "length": "teɣzi|length",
        "not_in": "mačči deg|not in",
        "or": "neɣ|or",
        "orange": "aččinawi|orange",
        "pink": "axuxi|pink",
        "play": "urar|play",
        "pressed": "yessed|pressed",
        "print": "siggez|print",
        "purple": "ajenjer|purple",
        "random": "s urway|random",
        "range": "azilal|range",
        "red": "azeggaɣ|red",
        "remove": "kkes|remove",
        "repeat": "ales|repeat",
        "return": "uɣal ɣer deffir|return",
        "right": "ayeffus|right",
        "sleep": "gen|sleep",
        "step": "takkayt|step",
        "times": "tikkal|times",
        "to": "ɣer|to",
        "to_list": "ɣer|to",
        "true": "swaswa|true",
        "turn": "zzi|turn",
        "while": "imi|while",
        "white": "amellal|white",
        "with": "akked|with",
        "yellow": "awreɣ|yellow",
        "DIGIT": "0123456789"
    },
    "kmr": {
        "False": "False",
        "True": "True",
        "add": "add",
        "and": "and",
        "ask": "ask",
        "at": "at",
        "black": "black",
        "blue": "blue",
        "brown": "brown",
        "call": "call",
        "clear": "clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "elif",
        "else": "else",
        "false": "false",
        "for": "for",
        "forward": "forward",
        "from": "from",
        "gray": "gray",
        "green": "green",
        "if": "if",
        "in": "in",
        "input": "input",
        "is": "is",
        "left": "left",
        "length": "length",
        "not_in": "not in",
        "or": "or",
        "orange": "orange",
        "pink": "pink",
        "play": "play",
        "pressed": "pressed",
        "print": "print",
        "purple": "purple",
        "random": "random",
        "range": "range",
        "red": "red",
        "remove": "remove",
        "repeat": "repeat",
        "return": "return",
        "right": "right",
        "sleep": "sleep",
        "step": "step",
        "times": "times",
        "to": "to",
        "to_list": "to",
        "true": "true",
        "turn": "turn",
        "while": "while",
        "white": "white",
        "with": "with",
        "yellow": "yellow",
        "DIGIT": "0123456789"
    },
    "kn": {
        "False": "False",
        "True": "True",
        "add": "add",
        "and": "and",
        "ask": "ask",
        "at": "at",
        "black": "black",
        "blue": "blue",
        "brown": "brown",
        "call": "call",
        "clear": "clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "elif",
        "else": "else",
        "false": "false",
        "for": "for",
        "forward": "forward",
        "from": "from",
        "gray": "gray",
        "green": "green",
        "if": "if",
        "in": "in",
        "input": "input",
        "is": "is",
        "left": "left",
        "length": "length",
        "not_in": "not in",
        "or": "or",
        "orange": "orange",
        "pink": "pink",
        "play": "play",
        "pressed": "pressed",
        "print": "print",
        "purple": "purple",
        "random": "random",
        "range": "range",
        "red": "red",
        "remove": "remove",
        "repeat": "repeat",
        "return": "return",
        "right": "right",
        "sleep": "sleep",
        "step": "step",
        "times": "times",
        "to": "to",
        "to_list": "to",
        "true": "true",
        "turn": "turn",
        "while": "while",
        "white": "white",
        "with": "with",
        "yellow": "yellow",
        "DIGIT": "0123456789"
    },
    "ko": {
        "False": "False",
        "True": "True",
        "add": "add",
        "and": "and",
        "ask": "ask",
        "at": "at",
        "black": "black",
        "blue": "blue",
        "brown": "brown",
        "call": "call",
        "clear": "clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "elif",
        "else": "else",
        "false": "false",
        "for": "for",
        "forward": "forward",
        "from": "from",
        "gray": "gray",
        "green": "green",
        "if": "if",
        "in": "in",
        "input": "input",
        "is": "is",
        "left": "left",
        "length": "length",
        "not_in": "not in",
        "or": "or",
        "orange": "orange",
        "pink": "pink",
        "play": "play",
        "pressed": "pressed",
        "print": "print",
        "purple": "purple",
        "random": "random",
        "range": "range",
        "red": "red",
        "remove": "remove",
        "repeat": "repeat",
        "return": "return",
        "right": "right",
        "sleep": "sleep",
        "step": "step",
        "times": "times",
        "to": "to",
        "to_list": "to",
        "true": "true",
        "turn": "turn",
        "while": "while",
        "white": "white",
        "with": "with",
        "yellow": "yellow",
        "DIGIT": "0123456789"
    },
    "lt": {
        "False": "False",
        "True": "True",
        "add": "add",
        "and": "and",
        "ask": "ask",
        "at": "at",
        "black": "black",
        "blue": "blue",
        "brown": "brown",
        "call": "call",
        "clear": "clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "elif",
        "else": "else",
        "false": "false",
        "for": "for",
        "forward": "forward",
        "from": "from",
        "gray": "gray",
        "green": "green",
        "if": "if",
        "in": "in",
        "input": "input",
        "is": "is",
        "left": "left",
        "length": "length",
        "not_in": "not in",
        "or": "or",
        "orange": "orange",
        "pink": "pink",
        "play": "play",
        "pressed": "pressed",
        "print": "print",
        "purple": "purple",
        "random": "random",
        "range": "range",
        "red": "red",
        "remove": "remove",
        "repeat": "repeat",
        "return": "return",
        "right": "right",
        "sleep": "sleep",
        "step": "step",
        "times": "times",
        "to": "to",
        "to_list": "to",
        "true": "true",
        "turn": "turn",
        "while": "while",
        "white": "white",
        "with": "with",
        "yellow": "yellow",
        "DIGIT": "0123456789"
    },
    "mi": {
        "False": "False",
        "True": "True",
        "add": "tāpiri|add",
        "and": ",|and",
        "ask": "pātai|ask",
        "at": "kei|at",
        "black": "pango|black",
        "blue": "kikorangi|blue",
        "brown": "parauri|brown",
        "call": "whakahaerea|call",
        "clear": "whakawāteangia|clear",
        "color": "kara|color",
        "comma": ",",
        "def": "tautuhi|def",
        "define": "tautuhi|define",
        "echo": "paoro|echo",
        "elif": "elif",
        "else": "else",
        "false": "false",
        "for": "mō|for",
        "forward": "neke whakamua|forward",
        "from": "i|from",
        "gray": "kiwikiwi|gray",
        "green": "kākāriki|green",
        "if": "mehemea|if",
        "in": "kei roto i|in",
        "input": "tāuru|input",
        "is": "ko|is",
        "left": "whakamauī|left",
        "length": "roa|length",
        "not_in": "kāore i roto i|not in",
        "or": "rānei|or",
        "orange": "karaka|orange",
        "pink": "māwhero|pink",
        "play": "whakatangihia|play",
        "pressed": "pēhia|pressed",
        "print": "tāngia|print",
        "purple": "waiporoporo|purple",
        "random": "random",
        "range": "whānui|range",
        "red": "whero|red",
        "remove": "tangohia|remove",
        "repeat": "tāruatia|repeat",
        "return": "whakahoki|return",
        "right": "whakamatau|right",
        "sleep": "e moe|sleep",
        "step": "step",
        "times": "ngā wā|times",
        "to": "ki|to",
        "to_list": "ki|to",
        "true": "true",
        "turn": "huri|turn",
        "while": "while",
        "white": "mā|white",
        "with": "with",
        "yellow": "kōwhai|yellow",
        "DIGIT": "0123456789"
    },
    "ml": {
        "False": "False",
        "True": "True",
        "add": "add",
        "and": "and",
        "ask": "ask",
        "at": "at",
        "black": "black",
        "blue": "blue",
        "brown": "brown",
        "call": "call",
        "clear": "clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "elif",
        "else": "else",
        "false": "false",
        "for": "for",
        "forward": "forward",
        "from": "from",
        "gray": "gray",
        "green": "green",
        "if": "if",
        "in": "in",
        "input": "input",
        "is": "is",
        "left": "left",
        "length": "length",
        "not_in": "not in",
        "or": "or",
        "orange": "orange",
        "pink": "pink",
        "play": "play",
        "pressed": "pressed",
        "print": "print",
        "purple": "purple",
        "random": "random",
        "range": "range",
        "red": "red",
        "remove": "remove",
        "repeat": "repeat",
        "return": "return",
        "right": "right",
        "sleep": "sleep",
        "step": "step",
        "times": "times",
        "to": "to",
        "to_list": "to",
        "true": "true",
        "turn": "turn",
        "while": "while",
        "white": "white",
        "with": "with",
        "yellow": "yellow",
        "DIGIT": "0123456789"
    },
    "mr": {
        "False": "खोटे|False",
        "True": "खरे|True",
        "add": "जोडा|add",
        "and": "आणि|and",
        "ask": "विचारा|ask",
        "at": "येथे|at",
        "black": "काळा|black",
        "blue": "निळा|blue",
        "brown": "तपकिरी|brown",
        "call": "कॉल|call",
        "clear": "स्पष्ट|clear",
        "color": "रंग|color",
        "comma": ",",
        "def": "def",
        "define": "व्याख्या|define",
        "echo": "प्रतिध्वनी|echo",
        "elif": "एलिफ|elif",
        "else": "इतर|else",
        "false": "खोटे|false",
        "for": "साठी|for",
        "forward": "पुढे|forward",
        "from": "पासून|from",
        "gray": "राखाडी|gray",
        "green": "हिरवा|green",
        "if": "जर|if",
        "in": "मध्ये|in",
        "input": "इनपुट|input",
        "is": "आहे|is",
        "left": "बाकी|left",
        "length": "लांबी|length",
        "not_in": "मध्ये नाही|not in",
        "or": "किंवा|or",
        "orange": "केशरी|orange",
        "pink": "गुलाबी|pink",
        "play": "खेळणे|play",
        "pressed": "दाबले|pressed",
        "print": "छापणे|print",
        "purple": "जांभळा|purple",
        "random": "यादृच्छिक|random",
        "range": "श्रेणी|range",
        "red": "लाल|red",
        "remove": "काढा|remove",
        "repeat": "पुनरावृत्ती|repeat",
        "return": "परत|return",
        "right": "बरोबर|right",
        "sleep": "झोप|sleep",
        "step": "पाऊल|step",
        "times": "वेळा|times",
        "to": "करण्यासाठी|to",
        "to_list": "करण्यासाठी|to",
        "true": "खरे|true",
        "turn": "वळणे|turn",
        "while": "असताना|while",
        "white": "पांढरा|white",
        "with": "सह|with",
        "yellow": "पिवळा|yellow",
        "DIGIT": "012२3३4४5५6६7७8८9९"
    },
    "ms": {
        "False": "False",
        "True": "True",
        "add": "tambah|add",
        "and": "dan|and",
        "ask": "tanya|ask",
        "at": "at",
        "black": "black",
        "blue": "blue",
        "brown": "brown",
        "call": "call",
        "clear": "clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "elif",
        "else": "else",
        "false": "false",
        "for": "for",
        "forward": "forward",
        "from": "from",
        "gray": "gray",
        "green": "green",
        "if": "if",
        "in": "in",
        "input": "input",
        "is": "is",
        "left": "left",
        "length": "length",
        "not_in": "not in",
        "or": "or",
        "orange": "orange",
        "pink": "pink",
        "play": "play",
        "pressed": "pressed",
        "print": "print",
        "purple": "purple",
        "random": "random",
        "range": "range",
        "red": "red",
        "remove": "remove",
        "repeat": "repeat",
        "return": "return",
        "right": "right",
        "sleep": "sleep",
        "step": "step",
        "times": "times",
        "to": "to",
        "to_list": "to",
        "true": "true",
        "turn": "turn",
        "while": "while",
        "white": "white",
        "with": "with",
        "yellow": "yellow",
        "DIGIT": "0123456789"
    },
    "my": {
        "False": "မှား|False",
        "True": "မှန်|True",
        "add": "ပေါင်း|add",
        "and": "နှင့်|and",
        "ask": "မေး|ask",
        "at": "မှာ|at",
        "black": "အနက်ရောင်|black",
        "blue": "အပြာရောင်|blue",
        "brown": "အညိုရောင်|brown",
        "call": "ခေါ်|call",
        "clear": "ဖျက်|clear",
        "color": "အရောင်|color",
        "comma": "၊|,",
        "def": "သတ်မှတ်|def",
        "define": "သတ်မှတ်|define",
        "echo": "လိုက်ဆို|echo",
        "elif": "ဒါဖြစ်ရင်|elif",
        "else": "ဒီဟာဖြစ်ရင်|else",
        "false": "မှား|false",
        "for": "အတွက်|for",
        "forward": "ရှေ့ဆက်|forward",
        "from": "မှ|from",
        "gray": "မီးခိုးရောင်|gray",
        "green": "အစိမ်းရောင်|green",
        "if": "ဖြစ်ရင်|if",
        "in": "အထဲမှာ|in",
        "input": "အဝင်|input",
        "is": "ဖြစ်တယ်|is",
        "left": "ဘယ်|left",
        "length": "အရှည်|length",
        "not_in": "မပါရင်|not in",
        "or": "သို့မဟုတ်|or",
        "orange": "လိမ္မော်ရောင်|orange",
        "pink": "ပန်းရောင်|pink",
        "play": "အသံထုတ်|play",
        "pressed": "ဖိခဲ့|pressed",
        "print": "စာရိုက်|print",
        "purple": "ခရမ်းရောင်|purple",
        "random": "ကျပမ်း|random",
        "range": "အကြား|range",
        "red": "အနီရောင်|red",
        "remove": "ဖယ်ရှား|remove",
        "repeat": "ထပ်လုပ်|repeat",
        "return": "ပြန်သွား|return",
        "right": "ညာ|right",
        "sleep": "အိပ်|sleep",
        "step": "အဆင့်|step",
        "times": "အမြှောက်|times",
        "to": "သို့|to",
        "to_list": "စာရင်းသို့|to",
        "true": "မှန်|true",
        "turn": "လှည့်|turn",
        "while": "ဖြစ်နေရင်|while",
        "white": "အဖြူရောင်|white",
        "with": "နဲ့|with",
        "yellow": "အဝါရောင်|yellow",
        "DIGIT": "0၀1၁2၂3၃4၄5၅6၆7၇8၈9၉"
    },
    "nb_NO": {
        "False": "False",
        "True": "True",
        "add": "legg|add",
        "and": "og|and",
        "ask": "spør|ask",
        "at": "på|at",
        "black": "black",
        "blue": "blue",
        "brown": "brown",
        "call": "call",
        "clear": "clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "ekko|echo",
        "elif": "elhvis|elif",
        "else": "ellers|else",
        "false": "false",
        "for": "for",
        "forward": "frem|forward",
        "from": "fra|from",
        "gray": "gray",
        "green": "green",
        "if": "hvis|if",
        "in": "i|in",
        "input": "inndata|input",
        "is": "er|is",
        "left": "venstre|left",
        "length": "lengde|length",
        "not_in": "not in",
        "or": "eller|or",
        "orange": "orange",
        "pink": "pink",
        "play": "play",
        "pressed": "pressed",
        "print": "skriv|print",
        "purple": "purple",
        "random": "tilfeldig|random",
        "range": "sekvens|range",
        "red": "red",
        "remove": "fjern|remove",
        "repeat": "gjenta|repeat",
        "return": "return",
        "right": "høyre|right",
        "sleep": "sov|sleep",
        "step": "steg|step",
        "times": "ganger|times",
        "to": "til|to",
        "to_list": "til|to",
        "true": "true",
        "turn": "snu|turn",
        "while": "mens|while",
        "white": "white",
        "with": "with",
        "yellow": "yellow",
        "DIGIT": "0123456789"
    },
    "ne": {
        "False": "False",
        "True": "True",
        "add": "add",
        "and": "and",
        "ask": "ask",
        "at": "at",
        "black": "black",
        "blue": "blue",
        "brown": "brown",
        "call": "call",
        "clear": "clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "elif",
        "else": "else",
        "false": "false",
        "for": "for",
        "forward": "forward",
        "from": "from",
        "gray": "gray",
        "green": "green",
        "if": "if",
        "in": "in",
        "input": "input",
        "is": "is",
        "left": "left",
        "length": "length",
        "not_in": "not in",
        "or": "or",
        "orange": "orange",
        "pink": "pink",
        "play": "play",
        "pressed": "pressed",
        "print": "print",
        "purple": "purple",
        "random": "random",
        "range": "range",
        "red": "red",
        "remove": "remove",
        "repeat": "repeat",
        "return": "return",
        "right": "right",
        "sleep": "sleep",
        "step": "step",
        "times": "times",
        "to": "to",
        "to_list": "to",
        "true": "true",
        "turn": "turn",
        "while": "while",
        "white": "white",
        "with": "with",
        "yellow": "yellow",
        "DIGIT": "0123456789"
    },
    "nl": {
        "False": "False",
        "True": "True",
        "add": "voeg|add",
        "and": "en|and",
        "ask": "vraag|ask",
        "at": "kies|at",
        "black": "zwart|black",
        "blue": "blauw|blue",
        "brown": "bruin|brown",
        "call": "roep|call",
        "clear": "wis|clear",
        "color": "kleur|color",
        "comma": ",",
        "def": "def",
        "define": "definieer|define",
        "echo": "echo",
        "elif": "alsanders|elif",
        "else": "anders|else",
        "false": "false",
        "for": "voor|for",
        "forward": "vooruit|forward",
        "from": "uit|from",
        "gray": "grijs|gray",
        "green": "groen|green",
        "if": "als|if",
        "in": "in",
        "input": "invoer|input",
        "is": "is",
        "left": "links|left",
        "length": "lengte|length",
        "not_in": "niet in|not in",
        "or": "of|or",
        "orange": "oranje|orange",
        "pink": "roze|pink",
        "play": "speel|play",
        "pressed": "ingedrukt|pressed",
        "print": "print",
        "purple": "paars|purple",
        "random": "willekeurig|random",
        "range": "bereik|range",
        "red": "rood|red",
        "remove": "verwijder|remove",
        "repeat": "herhaal|repeat",
        "return": "return",
        "right": "rechts|right",
        "sleep": "slaap|sleep",
        "step": "stap|step",
        "times": "keer|times",
        "to": "tot|to",
        "to_list": "toe aan|to",
        "true": "true",
        "turn": "draai|turn",
        "while": "zolang|while",
        "white": "wit|white",
        "with": "met|with",
        "yellow": "geel|yellow",
        "DIGIT": "0123456789"
    },
    "pa_PK": {
        "False": "False",
        "True": "True",
        "add": "دھن|add",
        "and": "تے|and",
        "ask": "سوال|ask",
        "at": "ستھتی|at",
        "black": "کالا|black",
        "blue": "نیلا|blue",
        "brown": "بھورا|brown",
        "call": "call",
        "clear": "clear",
        "color": "رنگ|color",
        "comma": "،|,",
        "def": "def",
        "define": "define",
        "echo": "فیر|echo",
        "elif": "ہور|elif",
        "else": "وکھرا|else",
        "false": "false",
        "for": "جدوں|for",
        "forward": "اگے|forward",
        "from": "سروت|from",
        "gray": "سلیٹی|gray",
        "green": "ہرا|green",
        "if": "جے|if",
        "in": "اندر|in",
        "input": "اینپٹ|input",
        "is": "سمان|is",
        "left": "کھبے|left",
        "length": "لمبائی|length",
        "not_in": "not in",
        "or": "یا|or",
        "orange": "سنترا|orange",
        "pink": "گلابی|pink",
        "play": "play",
        "pressed": "pressed",
        "print": "چپائی|print",
        "purple": "جامنی|purple",
        "random": "رلوان|random",
        "range": "سلسلہ|range",
        "red": "لال|red",
        "remove": "مٹاکے|remove",
        "repeat": "دہرا|repeat",
        "return": "return",
        "right": "سجے|right",
        "sleep": "نیند|sleep",
        "step": "سطر|step",
        "times": "ضرب|times",
        "to": "منزل|to",
        "to_list": "منزل|to",
        "true": "true",
        "turn": "موڑن|turn",
        "while": "جدکہ|while",
        "white": "چٹا|white",
        "with": "with",
        "yellow": "پیلا|yellow",
        "DIGIT": "0۰1۱2۲3۳4۴5۵6۶7۷8۸9۹"
    },
    "pap": {
        "False": "False",
        "True": "True",
        "add": "add",
        "and": "and",
        "ask": "ask",
        "at": "at",
        "black": "black",
        "blue": "blue",
        "brown": "brown",
        "call": "call",
        "clear": "clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "elif",
        "else": "else",
        "false": "false",
        "for": "for",
        "forward": "forward",
        "from": "from",
        "gray": "gray",
        "green": "green",
        "if": "if",
        "in": "in",
        "input": "input",
        "is": "is",
        "left": "left",
        "length": "length",
        "not_in": "not in",
        "or": "or",
        "orange": "orange",
        "pink": "pink",
        "play": "play",
        "pressed": "pressed",
        "print": "print",
        "purple": "purple",
        "random": "random",
        "range": "range",
        "red": "red",
        "remove": "remove",
        "repeat": "repeat",
        "return": "return",
        "right": "right",
        "sleep": "sleep",
        "step": "step",
        "times": "times",
        "to": "to",
        "to_list": "to",
        "true": "true",
        "turn": "turn",
        "while": "while",
        "white": "white",
        "with": "with",
        "yellow": "yellow",
        "DIGIT": "0123456789"
    },
    "peo": {
        "False": "False",
        "True": "True",
        "add": "add",
        "and": "and",
        "ask": "ask",
        "at": "at",
        "black": "black",
        "blue": "blue",
        "brown": "brown",
        "call": "call",
        "clear": "clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "elif",
        "else": "else",
        "false": "false",
        "for": "for",
        "forward": "forward",
        "from": "from",
        "gray": "gray",
        "green": "green",
        "if": "if",
        "in": "in",
        "input": "input",
        "is": "is",
        "left": "left",
        "length": "length",
        "not_in": "not in",
        "or": "or",
        "orange": "orange",
        "pink": "pink",
        "play": "play",
        "pressed": "pressed",
        "print": "print",
        "purple": "purple",
        "random": "random",
        "range": "range",
        "red": "red",
        "remove": "remove",
        "repeat": "repeat",
        "return": "return",
        "right": "right",
        "sleep": "sleep",
        "step": "step",
        "times": "times",
        "to": "to",
        "to_list": "to",
        "true": "true",
        "turn": "turn",
        "while": "while",
        "white": "white",
        "with": "with",
        "yellow": "yellow",
        "DIGIT": "0123456789"
    },
    "pl": {
        "False": "Fałsz|False",
        "True": "Prawda|True",
        "add": "dodaj|add",
        "and": "i|and",
        "ask": "zapytaj|ask",
        "at": "pozycja|at",
        "black": "czarny|black",
        "blue": "niebieski|blue",
        "brown": "brązowy|brown",
        "call": "zadzwoń|call",
        "clear": "wyczyść|clear",
        "color": "kolor|color",
        "comma": ",",
        "def": "def",
        "define": "definiuj|define",
        "echo": "dołącz|echo",
        "elif": "albo|elif",
        "else": "inaczej|else",
        "false": "fałsz|false",
        "for": "dla|for",
        "forward": "naprzód|forward",
        "from": "z|from",
        "gray": "szary|gray",
        "green": "zielony|green",
        "if": "jeżeli|if",
        "in": "w|in",
        "input": "wprowadź|input",
        "is": "to|is",
        "left": "lewo|left",
        "length": "długość|length",
        "not_in": "nie w|not in",
        "or": "lub|or",
        "orange": "pomarańczowy|orange",
        "pink": "różowy|pink",
        "play": "Odtwórz|play",
        "pressed": "naciśnięty|pressed",
        "print": "napisz|print",
        "purple": "fioletowy|purple",
        "random": "losowa|random",
        "range": "zakres|range",
        "red": "czerwony|red",
        "remove": "usuń|remove",
        "repeat": "powtórz|repeat",
        "return": "powróć|return",
        "right": "prawo|right",
        "sleep": "śpij|sleep",
        "step": "krok|step",
        "times": "razy|times",
        "to": "do|to",
        "to_list": "do|to",
        "true": "prawda|true",
        "turn": "obróć|turn",
        "while": "dopóki|while",
        "white": "biały|white",
        "with": "z|with",
        "yellow": "żółty|yellow",
        "DIGIT": "0123456789"
    },
    "pt_BR": {
        "False": "Falso|False",
        "True": "Verdadeiro|True",
        "add": "adicione|add",
        "and": "e|and",
        "ask": "pergunte|ask",
        "at": "'em'|at",
        "black": "preto|black",
        "blue": "azul|blue",
        "brown": "marrom|brown",
        "call": "chame|call",
        "clear": "limpe|clear",
        "color": "cor|color",
        "comma": ",",
        "def": "def",
        "define": "defina|define",
        "echo": "eco|echo",
        "elif": "senão se|elif",
        "else": "senão|else",
        "false": "falso|false",
        "for": "para|for",
        "forward": "adiante|forward",
        "from": "de|from",
        "gray": "cinza|gray",
        "green": "verde|green",
        "if": "se|if",
        "in": "em|in",
        "input": "entrada|input",
        "is": "é|is",
        "left": "esquerda|left",
        "length": "comprimento|length",
        "not_in": "não em|not in",
        "or": "ou|or",
        "orange": "laranja|orange",
        "pink": "rosa|pink",
        "play": "toque|play",
        "pressed": "apertado|pressed",
        "print": "imprima|print",
        "purple": "roxo|purple",
        "random": "aleatório|random",
        "range": "intervalo|range",
        "red": "vermelho|red",
        "remove": "remova|remove",
        "repeat": "repita|repeat",
        "return": "retorne|return",
        "right": "direita|right",
        "sleep": "durma|sleep",
        "step": "passo|step",
        "times": "vezes|times",
        "to": "para|to",
        "to_list": "a|to",
        "true": "verdadeiro|true",
        "turn": "gire|turn",
        "while": "enquanto|while",
        "white": "branco|white",
        "with": "com|with",
        "yellow": "amarelo|yellow",
        "DIGIT": "0123456789"
    },
    "pt_PT": {
        "False": "Falso|False",
        "True": "Verdadeiro|True",
        "add": "adicionar|add",
        "and": "e|and",
        "ask": "perguntar|ask",
        "at": "'no'|at",
        "black": "preto|black",
        "blue": "azul|blue",
        "brown": "castanho|brown",
        "call": "chamar|call",
        "clear": "limpar|clear",
        "color": "cor|color",
        "comma": ",",
        "def": "def",
        "define": "definir|define",
        "echo": "ecoar|echo",
        "elif": "elif",
        "else": "senão|else",
        "false": "falso|false",
        "for": "for",
        "forward": "avançar|forward",
        "from": "de|from",
        "gray": "cinzento|gray",
        "green": "verde|green",
        "if": "se|if",
        "in": "em|in",
        "input": "inserir|input",
        "is": "é|is",
        "left": "esquerda|left",
        "length": "comprimento|length",
        "not_in": "não em|not in",
        "or": "ou|or",
        "orange": "cor de laranja|orange",
        "pink": "cor de rosa|pink",
        "play": "tocar|play",
        "pressed": "pressionado|pressed",
        "print": "imprimir|print",
        "purple": "roxo|purple",
        "random": "aleatório|random",
        "range": "intervalo|range",
        "red": "vermelho|red",
        "remove": "remover|remove",
        "repeat": "repetir|repeat",
        "return": "retornar|return",
        "right": "direita|right",
        "sleep": "dormir|sleep",
        "step": "passo|step",
        "times": "vezes|times",
        "to": "to",
        "to_list": "para|to",
        "true": "verdadeiro|true",
        "turn": "virar|turn",
        "while": "enquanto|while",
        "white": "branco|white",
        "with": "com|with",
        "yellow": "amarelo|yellow",
        "DIGIT": "0123456789"
    },
    "ro": {
        "False": "False",
        "True": "True",
        "add": "adună|add",
        "and": "si|and",
        "ask": "întreabă|ask",
        "at": "la|at",
        "black": "negru|black",
        "blue": "albastru|blue",
        "brown": "maro|brown",
        "call": "call",
        "clear": "șterge|clear",
        "color": "culoare|color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "altfel dacă|elif",
        "else": "else",
        "false": "false",
        "for": "pentru|for",
        "forward": "înainte|forward",
        "from": "de la|from",
        "gray": "gri|gray",
        "green": "verde|green",
        "if": "if",
        "in": "in",
        "input": "intrare|input",
        "is": "is",
        "left": "stânga|left",
        "length": "lungime|length",
        "not_in": "nu în|not in",
        "or": "sau|or",
        "orange": "portocaliu|orange",
        "pink": "roz|pink",
        "play": "play",
        "pressed": "apăsat|pressed",
        "print": "print",
        "purple": "mov|purple",
        "random": "aleatoriu|random",
        "range": "interval|range",
        "red": "roșu|red",
        "remove": "elimină|remove",
        "repeat": "repetă|repeat",
        "return": "return",
        "right": "dreapta|right",
        "sleep": "sleep",
        "step": "pas|step",
        "times": "inmulțit|times",
        "to": "către|to",
        "to_list": "către|to",
        "true": "true",
        "turn": "intoarce|turn",
        "while": "în timp ce|while",
        "white": "alb|white",
        "with": "with",
        "yellow": "galben|yellow",
        "DIGIT": "0123456789"
    },
    "ru": {
        "False": "Ложь|False",
        "True": "Истина|True",
        "add": "добавить|add",
        "and": "и|and",
        "ask": "спросить|ask",
        "at": "в|at",
        "black": "чёрный|black",
        "blue": "синий|blue",
        "brown": "коричневый|brown",
        "call": "вызвать|call",
        "clear": "очистить|clear",
        "color": "цвет|color",
        "comma": ",",
        "def": "def",
        "define": "определить|define",
        "echo": "повторить|echo",
        "elif": "иначе, если|elif",
        "else": "иначе|else",
        "false": "ложь|false",
        "for": "для|for",
        "forward": "вперёд|forward",
        "from": "из|from",
        "gray": "серый|gray",
        "green": "зелёный|green",
        "if": "если|if",
        "in": "в|in",
        "input": "ввод|input",
        "is": "это|is",
        "left": "налево|left",
        "length": "длина|length",
        "not_in": "не в|not in",
        "or": "или|or",
        "orange": "оранжевый|orange",
        "pink": "розовый|pink",
        "play": "play",
        "pressed": "нажмите|pressed",
        "print": "печатать|print",
        "purple": "пурпурный|purple",
        "random": "случайном|random",
        "range": "диапазон|range",
        "red": "красный|red",
        "remove": "удалить|remove",
        "repeat": "повторить|repeat",
        "return": "вернуть|return",
        "right": "направо|right",
        "sleep": "заснуть|sleep",
        "step": "шаг|step",
        "times": "раз|times",
        "to": "до|to",
        "to_list": "в|to",
        "true": "истина|true",
        "turn": "повернуть|turn",
        "while": "пока|while",
        "white": "белый|white",
        "with": "с|with",
        "yellow": "жёлтый|yellow",
        "DIGIT": "0123456789"
    },
    "sl": {
        "False": "False",
        "True": "True",
        "add": "dodaj|add",
        "and": "in|and",
        "ask": "Vprašaj|ask",
        "at": "pri|at",
        "black": "črna|black",
        "blue": "modra|blue",
        "brown": "rjava|brown",
        "call": "pokliči|call",
        "clear": "počisti|clear",
        "color": "barva|color",
        "comma": ",",
        "def": "def",
        "define": "definiraj|define",
        "echo": "ponovi|echo",
        "elif": "aliče|elif",
        "else": "drugače|else",
        "false": "false",
        "for": "za|for",
        "forward": "naprej|forward",
        "from": "od|from",
        "gray": "siva|gray",
        "green": "zelena|green",
        "if": "če|if",
        "in": "v|in",
        "input": "vnos|input",
        "is": "je|is",
        "left": "levo|left",
        "length": "dolžina|length",
        "not_in": "ni v|not in",
        "or": "ali|or",
        "orange": "oranžna|orange",
        "pink": "roza|pink",
        "play": "zaigraj|play",
        "pressed": "pritisnjen|pressed",
        "print": "izpiši|print",
        "purple": "vijolična|purple",
        "random": "naključno|random",
        "range": "razpon|range",
        "red": "rdeča|red",
        "remove": "odstrani|remove",
        "repeat": "ponovi|repeat",
        "return": "vrni|return",
        "right": "desno|right",
        "sleep": "zaspi|sleep",
        "step": "korak|step",
        "times": "krat|times",
        "to": "do|to",
        "to_list": "do|to",
        "true": "true",
        "turn": "obrni|turn",
        "while": "medtem ko|while",
        "white": "bela|white",
        "with": "z|with",
        "yellow": "rumena|yellow",
        "DIGIT": "0123456789"
    },
    "sq": {
        "False": "False",
        "True": "True",
        "add": "shtoni|add",
        "and": "dhe|and",
        "ask": "pyet|ask",
        "at": "në|at",
        "black": "zezë|black",
        "blue": "blu|blue",
        "brown": "kafe|brown",
        "call": "thirr|call",
        "clear": "pastro|clear",
        "color": "ngjyrë|color",
        "comma": ",",
        "def": "def",
        "define": "defino|define",
        "echo": "përsërit|echo",
        "elif": "nendryshe|elif",
        "else": "ndryshe|else",
        "false": "false",
        "for": "për|for",
        "forward": "përpara|forward",
        "from": "nga|from",
        "gray": "gri|gray",
        "green": "jeshile|green",
        "if": "nëse|if",
        "in": "në|in",
        "input": "hyrje|input",
        "is": "është|is",
        "left": "majtas|left",
        "length": "gjatësia|length",
        "not_in": "jo në|not in",
        "or": "ose|or",
        "orange": "portokalli|orange",
        "pink": "rozë|pink",
        "play": "play",
        "pressed": "shtypur|pressed",
        "print": "printo|print",
        "purple": "vjollcë|purple",
        "random": "rastësi|random",
        "range": "varg|range",
        "red": "kuqe|red",
        "remove": "hiqni|remove",
        "repeat": "përsërit|repeat",
        "return": "rikthe|return",
        "right": "drejtë|right",
        "sleep": "fle|sleep",
        "step": "hap|step",
        "times": "her|times",
        "to": "deri|to",
        "to_list": "deri|to",
        "true": "true",
        "turn": "kthesë|turn",
        "while": "derisa|while",
        "white": "bardhë|white",
        "with": "me|with",
        "yellow": "verdhë|yellow",
        "DIGIT": "0123456789"
    },
    "sr": {
        "False": "Нетачно|False",
        "True": "Тачно|True",
        "add": "додај|add",
        "and": "и|and",
        "ask": "питај|ask",
        "at": "на|at",
        "black": "црна|black",
        "blue": "плава|blue",
        "brown": "браон|brown",
        "call": "позови|call",
        "clear": "очисти|clear",
        "color": "боја|color",
        "comma": ",",
        "def": "деф|def",
        "define": "дефиниши|define",
        "echo": "покажи|echo",
        "elif": "иначе ако|elif",
        "else": "иначе|else",
        "false": "нетачно|false",
        "for": "за|for",
        "forward": "напред|forward",
        "from": "од|from",
        "gray": "сива|gray",
        "green": "зелена|green",
        "if": "ако|if",
        "in": "у|in",
        "input": "улаз|input",
        "is": "је|is",
        "left": "лево|left",
        "length": "дужина|length",
        "not_in": "не/није у|not in",
        "or": "или|or",
        "orange": "наранџаста|orange",
        "pink": "розе|pink",
        "play": "играј|play",
        "pressed": "притиснуто|pressed",
        "print": "штампај|print",
        "purple": "љубичаста|purple",
        "random": "насумично|random",
        "range": "опсег|range",
        "red": "црвена|red",
        "remove": "обриши|remove",
        "repeat": "понови|repeat",
        "return": "врати|return",
        "right": "десно|right",
        "sleep": "спавање|sleep",
        "step": "корак|step",
        "times": "пута|times",
        "to": "до|to",
        "to_list": "до|to",
        "true": "тачно|true",
        "turn": "окрени|turn",
        "while": "док|while",
        "white": "бела|white",
        "with": "са|with",
        "yellow": "жута|yellow",
        "DIGIT": "0123456789"
    },
    "sv": {
        "False": "False",
        "True": "True",
        "add": "addera|add",
        "and": "och|and",
        "ask": "fråga|ask",
        "at": "vid|at",
        "black": "svart|black",
        "blue": "blå|blue",
        "brown": "brun|brown",
        "call": "anropa|call",
        "clear": "rensa|clear",
        "color": "färg|color",
        "comma": ",",
        "def": "def",
        "define": "definiera|define",
        "echo": "eko|echo",
        "elif": "anom|elif",
        "else": "annars|else",
        "false": "false",
        "for": "för|for",
        "forward": "framåt|forward",
        "from": "från|from",
        "gray": "grå|gray",
        "green": "grön|green",
        "if": "om|if",
        "in": "i|in",
        "input": "inmatning|input",
        "is": "är|is",
        "left": "vänster|left",
        "length": "längd|length",
        "not_in": "inte i|not in",
        "or": "eller|or",
        "orange": "orange",
        "pink": "rosa|pink",
        "play": "play",
        "pressed": "nedtryckt|pressed",
        "print": "skriv|print",
        "purple": "lila|purple",
        "random": "slump|random",
        "range": "intervall|range",
        "red": "röd|red",
        "remove": "radera|remove",
        "repeat": "upprepa|repeat",
        "return": "returnera|return",
        "right": "höger|right",
        "sleep": "sov|sleep",
        "step": "steg|step",
        "times": "gånger|times",
        "to": "till|to",
        "to_list": "till|to",
        "true": "true",
        "turn": "sväng|turn",
        "while": "medan|while",
        "white": "vit|white",
        "with": "med|with",
        "yellow": "gul|yellow",
        "DIGIT": "0123456789"
    },
    "sw": {
        "False": "False",
        "True": "True",
        "add": "add",
        "and": "and",
        "ask": "ask",
        "at": "at",
        "black": "black",
        "blue": "blue",
        "brown": "brown",
        "call": "call",
        "clear": "clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "elif",
        "else": "else",
        "false": "false",
        "for": "for",
        "forward": "forward",
        "from": "from",
        "gray": "gray",
        "green": "green",
        "if": "if",
        "in": "in",
        "input": "input",
        "is": "is",
        "left": "left",
        "length": "length",
        "not_in": "not in",
        "or": "or",
        "orange": "orange",
        "pink": "pink",
        "play": "play",
        "pressed": "pressed",
        "print": "print",
        "purple": "purple",
        "random": "random",
        "range": "range",
        "red": "red",
        "remove": "remove",
        "repeat": "repeat",
        "return": "return",
        "right": "right",
        "sleep": "sleep",
        "step": "step",
        "times": "times",
        "to": "to",
        "to_list": "to",
        "true": "true",
        "turn": "turn",
        "while": "while",
        "white": "white",
        "with": "with",
        "yellow": "yellow",
        "DIGIT": "0123456789"
    },
    "ta": {
        "False": "False",
        "True": "True",
        "add": "add",
        "and": "and",
        "ask": "ask",
        "at": "at",
        "black": "black",
        "blue": "blue",
        "brown": "brown",
        "call": "call",
        "clear": "clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "elif",
        "else": "else",
        "false": "false",
        "for": "for",
        "forward": "forward",
        "from": "from",
        "gray": "gray",
        "green": "green",
        "if": "if",
        "in": "in",
        "input": "input",
        "is": "is",
        "left": "left",
        "length": "length",
        "not_in": "not in",
        "or": "or",
        "orange": "orange",
        "pink": "pink",
        "play": "play",
        "pressed": "pressed",
        "print": "print",
        "purple": "purple",
        "random": "random",
        "range": "range",
        "red": "red",
        "remove": "remove",
        "repeat": "repeat",
        "return": "return",
        "right": "right",
        "sleep": "sleep",
        "step": "step",
        "times": "times",
        "to": "to",
        "to_list": "to",
        "true": "true",
        "turn": "turn",
        "while": "while",
        "white": "white",
        "with": "with",
        "yellow": "yellow",
        "DIGIT": "0123456789"
    },
    "te": {
        "False": "False",
        "True": "True",
        "add": "జోడించు|add",
        "and": "మరియు|and",
        "ask": "అడగండి|ask",
        "at": "వద్ద|at",
        "black": "black",
        "blue": "blue",
        "brown": "brown",
        "call": "call",
        "clear": "తుడిచివేయి|clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "ప్రతిధ్వని|echo",
        "elif": "మరొకటి ఉంటే|elif",
        "else": "లేకపోతే|else",
        "false": "false",
        "for": "కోసం|for",
        "forward": "ముందుకు|forward",
        "from": "నుండి|from",
        "gray": "gray",
        "green": "green",
        "if": "ఉంటే|if",
        "in": "मेలో|in",
        "input": "ఇన్పుట్|input",
        "is": "ఉంది|is",
        "left": "left",
        "length": "పొడవు|length",
        "not_in": "not in",
        "or": "లేదా|or",
        "orange": "orange",
        "pink": "pink",
        "play": "play",
        "pressed": "pressed",
        "print": "ముద్రణ|print",
        "purple": "purple",
        "random": "యాదృచ్ఛికంగా|random",
        "range": "పరిధి|range",
        "red": "red",
        "remove": "తొలగించు|remove",
        "repeat": "పునరావృతం|repeat",
        "return": "return",
        "right": "right",
        "sleep": "నిద్ర|sleep",
        "step": "అడుగు|step",
        "times": "సార్లు|times",
        "to": "కు|to",
        "to_list": "కు|to",
        "true": "true",
        "turn": "మలుపు|turn",
        "while": "అయితే|while",
        "white": "white",
        "with": "with",
        "yellow": "yellow",
        "DIGIT": "0123456789"
    },
    "th": {
        "False": "False",
        "True": "True",
        "add": "เพิ่ม|add",
        "and": "และ|and",
        "ask": "ถามว่า|ask",
        "at": "แบบ|at",
        "black": "ดำ|black",
        "blue": "น้ำเงิน|blue",
        "brown": "น้ำตาล|brown",
        "call": "call",
        "clear": "ลบกระดาน|clear",
        "color": "สี|color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "พูด|echo",
        "elif": "หรือถ้า|elif",
        "else": "ไม่อย่างนั้น|else",
        "false": "false",
        "for": "ให้|for",
        "forward": "เดินหน้า|forward",
        "from": "จาก|from",
        "gray": "เทา|gray",
        "green": "เขียว|green",
        "if": "ถ้า|if",
        "in": "อยู่ใน|in",
        "input": "รับข้อมูล|input",
        "is": "คือ|is",
        "left": "ซ้าย|left",
        "length": "ความยาว|length",
        "not_in": "ไม่อยู่ใน|not in",
        "or": "หรือ|or",
        "orange": "ส้ม|orange",
        "pink": "ชมพู|pink",
        "play": "play",
        "pressed": "ปุ่มที่ถูกกด|pressed",
        "print": "แสดง|print",
        "purple": "ม่วง|purple",
        "random": "สุ่ม|random",
        "range": "ช่วง|range",
        "red": "แดง|red",
        "remove": "ลบ|remove",
        "repeat": "ทำซ้ำ|repeat",
        "return": "return",
        "right": "ขวา|right",
        "sleep": "รอ|sleep",
        "step": "เดิน|step",
        "times": "ครั้ง|times",
        "to": "จนถึง|to",
        "to_list": "ไปยัง|to",
        "true": "true",
        "turn": "เลี้ยว|turn",
        "while": "เมื่อไหร่ก็ตามที่|while",
        "white": "ขาว|white",
        "with": "with",
        "yellow": "เหลือง|yellow",
        "DIGIT": "0123456789"
    },
    "tl": {
        "False": "False",
        "True": "True",
        "add": "add",
        "and": "and",
        "ask": "ask",
        "at": "at",
        "black": "black",
        "blue": "blue",
        "brown": "brown",
        "call": "call",
        "clear": "clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "elif",
        "else": "else",
        "false": "false",
        "for": "for",
        "forward": "forward",
        "from": "from",
        "gray": "gray",
        "green": "green",
        "if": "if",
        "in": "in",
        "input": "input",
        "is": "is",
        "left": "left",
        "length": "length",
        "not_in": "not in",
        "or": "or",
        "orange": "orange",
        "pink": "pink",
        "play": "play",
        "pressed": "pressed",
        "print": "print",
        "purple": "purple",
        "random": "random",
        "range": "range",
        "red": "red",
        "remove": "remove",
        "repeat": "repeat",
        "return": "return",
        "right": "right",
        "sleep": "sleep",
        "step": "step",
        "times": "times",
        "to": "to",
        "to_list": "to",
        "true": "true",
        "turn": "turn",
        "while": "while",
        "white": "white",
        "with": "with",
        "yellow": "yellow",
        "DIGIT": "0123456789"
    },
    "tn": {
        "False": "False",
        "True": "True",
        "add": "tsenya|add",
        "and": "and",
        "ask": "ask",
        "at": "at",
        "black": "black",
        "blue": "blue",
        "brown": "brown",
        "call": "call",
        "clear": "clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "elif",
        "else": "else",
        "false": "false",
        "for": "for",
        "forward": "forward",
        "from": "from",
        "gray": "gray",
        "green": "green",
        "if": "if",
        "in": "in",
        "input": "input",
        "is": "is",
        "left": "left",
        "length": "length",
        "not_in": "not in",
        "or": "or",
        "orange": "orange",
        "pink": "pink",
        "play": "play",
        "pressed": "pressed",
        "print": "print",
        "purple": "purple",
        "random": "random",
        "range": "range",
        "red": "red",
        "remove": "remove",
        "repeat": "repeat",
        "return": "return",
        "right": "right",
        "sleep": "sleep",
        "step": "step",
        "times": "times",
        "to": "to",
        "to_list": "to",
        "true": "true",
        "turn": "turn",
        "while": "while",
        "white": "white",
        "with": "with",
        "yellow": "yellow",
        "DIGIT": "0123456789"
    },
    "tr": {
        "False": "Yanlış|False",
        "True": "Doğru|True",
        "add": "ekle|add",
        "and": "ve|and",
        "ask": "sor|ask",
        "at": "içinden|at",
        "black": "siyah|black",
        "blue": "mavi|blue",
        "brown": "kahverengi|brown",
        "call": "çağır|call",
        "clear": "temizle|clear",
        "color": "renk|color",
        "comma": ",",
        "def": "tanı|def",
        "define": "tanımla|define",
        "echo": "yankıla|echo",
        "elif": "değileğer|elif",
        "else": "değilse|else",
        "false": "yanlış|false",
        "for": "şunun için|for",
        "forward": "ileri|forward",
        "from": "şuradan|from",
        "gray": "gri|gray",
        "green": "yeşil|green",
        "if": "eğer|if",
        "in": "şunda|in",
        "input": "girdi|input",
        "is": "eşit|is",
        "left": "sol|left",
        "length": "uzunluk|length",
        "not_in": "şunda değil|not in",
        "or": "veya|or",
        "orange": "turuncu|orange",
        "pink": "pembe|pink",
        "play": "oynat|play",
        "pressed": "basılı|pressed",
        "print": "yazdır|print",
        "purple": "mor|purple",
        "random": "rastgele|random",
        "range": "aralık|range",
        "red": "kırmızı|red",
        "remove": "kaldır|remove",
        "repeat": "tekrarla|repeat",
        "return": "geridön|return",
        "right": "sağ|right",
        "sleep": "uyu|sleep",
        "step": "adım|step",
        "times": "kere|times",
        "to": "şuraya|to",
        "to_list": "şuraya|to",
        "true": "doğru|true",
        "turn": "döndür|turn",
        "while": "şu iken|while",
        "white": "beyaz|white",
        "with": "şununla|with",
        "yellow": "sarı|yellow",
        "DIGIT": "0123456789"
    },
    "uk": {
        "False": "False",
        "True": "True",
        "add": "додай|add",
        "and": "і|and",
        "ask": "запитай|ask",
        "at": "на позиції|at",
        "black": "чорний|black",
        "blue": "синій|blue",
        "brown": "коричневий|brown",
        "call": "виклик|call",
        "clear": "очистити|clear",
        "color": "колір|color",
        "comma": ",",
        "def": "зах|def",
        "define": "визначити|define",
        "echo": "ехо|echo",
        "elif": "інакше якщо|elif",
        "else": "інакше|else",
        "false": "false",
        "for": "для|for",
        "forward": "вперед|forward",
        "from": "iз|з|from",
        "gray": "сірий|gray",
        "green": "зелений|green",
        "if": "якщо|if",
        "in": "в|in",
        "input": "введи|input",
        "is": "це|is",
        "left": "вліво|left",
        "length": "довжина|length",
        "not_in": "не в|not in",
        "or": "або|or",
        "orange": "оранжевий|orange",
        "pink": "рожевий|pink",
        "play": "грати|play",
        "pressed": "натиснув|pressed",
        "print": "друкуй|print",
        "purple": "фіолетовий|purple",
        "random": "випадковий|випадковій|random",
        "range": "діапазон|діапазонi|range",
        "red": "червоний|red",
        "remove": "видали|remove",
        "repeat": "повтори|repeat",
        "return": "return",
        "right": "вправо|right",
        "sleep": "почекай|sleep",
        "step": "крок|step",
        "times": "разів|рази|раз|times",
        "to": "до|to",
        "to_list": "до|to",
        "true": "true",
        "turn": "поверни|turn",
        "while": "тоді як|while",
        "white": "білий|white",
        "with": "з|with",
        "yellow": "жовтий|yellow",
        "DIGIT": "0123456789"
    },
    "ur": {
        "False": "False",
        "True": "True",
        "add": "شامل|add",
        "and": "اور|and",
        "ask": "بتاؤ|ask",
        "at": "کوئی|at",
        "black": "کالا|black",
        "blue": "نیلا|blue",
        "brown": "براؤن|brown",
        "call": "call",
        "clear": "صاف|clear",
        "color": "رنگ|color",
        "comma": "،|,",
        "def": "def",
        "define": "define",
        "echo": "پکار|echo",
        "elif": "یااگر|elif",
        "else": "ورنہ|else",
        "false": "false",
        "for": "فی|for",
        "forward": "آگے|forward",
        "from": "سے|from",
        "gray": "گرے|gray",
        "green": "سبز|green",
        "if": "اگر|if",
        "in": "میں|in",
        "input": "بتاؤ|input",
        "is": "ہے|is",
        "left": "بائیں|left",
        "length": "لمبائی|length",
        "not_in": "نہیں ہے|not in",
        "or": "یا|or",
        "orange": "اورینج|orange",
        "pink": "گلابی|pink",
        "play": "play",
        "pressed": "دبا ہوا|pressed",
        "print": "دکھاؤ|print",
        "purple": "جامنی|purple",
        "random": "سا|random",
        "range": "حد|range",
        "red": "سرخ|red",
        "remove": "نکالو|remove",
        "repeat": "مکرر|repeat",
        "return": "return",
        "right": "دائیں|right",
        "sleep": "آرام|sleep",
        "step": "قدم|step",
        "times": "دفعہ|times",
        "to": "سے|to",
        "to_list": "اندر|to",
        "true": "true",
        "turn": "مڑو|turn",
        "while": "جبتک|while",
        "white": "سفید|white",
        "with": "with",
        "yellow": "پیلا|yellow",
        "DIGIT": "0123456789"
    },
    "uz": {
        "False": "False",
        "True": "True",
        "add": "add",
        "and": "and",
        "ask": "ask",
        "at": "at",
        "black": "black",
        "blue": "blue",
        "brown": "brown",
        "call": "call",
        "clear": "clear",
        "color": "color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "elif",
        "else": "else",
        "false": "false",
        "for": "for",
        "forward": "forward",
        "from": "from",
        "gray": "gray",
        "green": "green",
        "if": "if",
        "in": "in",
        "input": "input",
        "is": "is",
        "left": "left",
        "length": "length",
        "not_in": "not in",
        "or": "or",
        "orange": "orange",
        "pink": "pink",
        "play": "play",
        "pressed": "pressed",
        "print": "print",
        "purple": "purple",
        "random": "random",
        "range": "range",
        "red": "red",
        "remove": "remove",
        "repeat": "repeat",
        "return": "return",
        "right": "right",
        "sleep": "sleep",
        "step": "step",
        "times": "times",
        "to": "to",
        "to_list": "to",
        "true": "true",
        "turn": "turn",
        "while": "while",
        "white": "white",
        "with": "with",
        "yellow": "yellow",
        "DIGIT": "0123456789"
    },
    "vi": {
        "False": "False",
        "True": "True",
        "add": "add",
        "and": "và|and",
        "ask": "hỏi|ask",
        "at": "at",
        "black": "đen|black",
        "blue": "lam|blue",
        "brown": "nâu|brown",
        "call": "call",
        "clear": "clear",
        "color": "màu|color",
        "comma": ",",
        "def": "def",
        "define": "define",
        "echo": "đáp|echo",
        "elif": "elif",
        "else": "else",
        "false": "false",
        "for": "for",
        "forward": "tiến|forward",
        "from": "from",
        "gray": "xám|gray",
        "green": "lục|green",
        "if": "nếu|if",
        "in": "in",
        "input": "input",
        "is": "là|is",
        "left": "trái|left",
        "length": "length",
        "not_in": "not in",
        "or": "hoặc|or",
        "orange": "cam|orange",
        "pink": "hồng|pink",
        "play": "play",
        "pressed": "pressed",
        "print": "xuất|print",
        "purple": "tím|purple",
        "random": "ngẫu_nhiên|random",
        "range": "range",
        "red": "đỏ|red",
        "remove": "remove",
        "repeat": "repeat",
        "return": "return",
        "right": "phải|right",
        "sleep": "sleep",
        "step": "step",
        "times": "times",
        "to": "to",
        "to_list": "to",
        "true": "true",
        "turn": "quay|turn",
        "while": "while",
        "white": "trắng|white",
        "with": "with",
        "yellow": "vàng|yellow",
        "DIGIT": "0123456789"
    },
    "zh_Hans": {
        "False": "错的|False",
        "True": "真的|True",
        "add": "加|add",
        "and": "并且|and",
        "ask": "提问|ask",
        "at": "在|at",
        "black": "黑色|black",
        "blue": "蓝色|blue",
        "brown": "棕色|brown",
        "call": "调用|call",
        "clear": "清除|clear",
        "color": "颜色|color",
        "comma": "，|,",
        "def": "定义函数|def",
        "define": "定义|define",
        "echo": "回声|echo",
        "elif": "否则如果|elif",
        "else": "否则|else",
        "false": "错的|false",
        "for": "取|for",
        "forward": "向前|forward",
        "from": "从|from",
        "gray": "灰色|gray",
        "green": "绿色|green",
        "if": "如果|if",
        "in": "在里面|in",
        "input": "输入|input",
        "is": "是|is",
        "left": "左|left",
        "length": "长度|length",
        "not_in": "不在|not in",
        "or": "或|or",
        "orange": "橙色|orange",
        "pink": "粉红色|pink",
        "play": "运行|play",
        "pressed": "按下|pressed",
        "print": "打印|print",
        "purple": "紫色|purple",
        "random": "随机|random",
        "range": "范围|range",
        "red": "红色|red",
        "remove": "移除|remove",
        "repeat": "重复|repeat",
        "return": "返回|return",
        "right": "右|right",
        "sleep": "睡眠|sleep",
        "step": "步|step",
        "times": "次|times",
        "to": "到|to",
        "to_list": "到|to",
        "true": "真的|true",
        "turn": "旋转|turn",
        "while": "当|while",
        "white": "白色|white",
        "with": "对|with",
        "yellow": "黄色|yellow",
        "DIGIT": "0123456789"
    },
    "zh_Hant": {
        "False": "False",
        "True": "True",
        "add": "加|add",
        "and": "和|and",
        "ask": "問|ask",
        "at": "在|at",
        "black": "黑色|black",
        "blue": "藍色|blue",
        "brown": "棕色|brown",
        "call": "call",
        "clear": "清除|clear",
        "color": "顏色|color",
        "comma": "，|,",
        "def": "def",
        "define": "define",
        "echo": "echo",
        "elif": "否則如果|elif",
        "else": "否則|else",
        "false": "false",
        "for": "for",
        "forward": "向前|forward",
        "from": "從|from",
        "gray": "灰色|gray",
        "green": "綠色|green",
        "if": "如果|if",
        "in": "in",
        "input": "輸入|input",
        "is": "是|is",
        "left": "左|left",
        "length": "長度|length",
        "not_in": "not in",
        "or": "或|or",
        "orange": "橙色|orange",
        "pink": "粉紅色|pink",
        "play": "play",
        "pressed": "已按下|pressed",
        "print": "列印|print",
        "purple": "紫色|purple",
        "random": "隨機|random",
        "range": "範圍|range",
        "red": "紅色|red",
        "remove": "移除|remove",
        "repeat": "重複|repeat",
        "return": "return",
        "right": "右|right",
        "sleep": "睡眠|sleep",
        "step": "步|step",
        "times": "次|times",
        "to": "到|to",
        "to_list": "到|to",
        "true": "true",
        "turn": "轉動|turn",
        "while": "當|while",
        "white": "白色|white",
        "with": "with",
        "yellow": "黃色|yellow",
        "DIGIT": "0123456789"
    }
}